import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useId } from 'react-use-id-hook';
import './styles.scss';
import {
  AppLink,
  EventPropagationStopper,
  ExpandMode,
  MaterialIcon,
  MaterialIconName,
  Pill,
  PillTypes,
  Well,
  WellType,
} from 'client/shared/components/base';
import { BenchmarkIndicator } from 'client/shared/components/benchmark-indicator';
import {
  LineChart,
  TimeBreakdown,
  TimeBreakdownInterval,
} from 'client/shared/components/data-viz-d3/line-chart';
import { COLORS_LIBERTY_HEX } from 'client/shared/core/colors';
import {
  MMMM_YEAR_FORMAT,
  YEAR_FORMAT,
  AnalyticsDomain,
  ActivationState,
  VisualizationType,
  SavedVisualizationFilterStates,
  wrap,
  TrackDatum,
  TrackVariable,
  FipsArea,
  VariableDisplayType,
  NationalDataFips,
} from 'core';
import { DifferenceIndicator } from 'client/shared/components/difference-indicator';
import { CollapseBtn } from 'client/shared/components/base/collapse-btn';
import {
  AnalyticsValueType,
  BenchmarkFilter,
  DateLevel,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  analyticsBenchmarkToClient,
  formatIndicatorValue,
  getFilteredBenchmarkFooter,
  getFilteredBenchmarkValue,
} from 'client/shared/core/performance-data';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { useGenerateImage } from 'client/shared/hooks';
import { Tooltip } from '../tooltip';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { EmbedContext } from 'client/shared/contexts/embed-context';
import { EmbedLogoFooter } from '../embed-logo-footer';
import { EmbedDataPointContext } from '../visualization-picker';
import { useWindowSize } from 'client/shared/helpers/hooks';
import { DownloadImageButton } from '../download-image';
import { demographicSegmentLabel } from '../domain-card-indicator';

export interface Props {
  readonly benchmarkFilter: BenchmarkFilter;
  readonly className?: string;
  readonly domain: AnalyticsDomain;
  readonly variable: TrackVariable;
  readonly currentFips: FipsArea;
  readonly expanded?: boolean;
  readonly toggleExpanded: (variableId: string) => void;
  readonly embedContext?: EmbedDataPointContext;
  readonly showFooter?: boolean;
  readonly saveVisualization?: (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => Promise<void>;
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly displayType?: VariableDisplayType;
  readonly comparisonGroupId?: string;
}

const calculateChartMarginLeft = (maxAxisValue: number): number => {
  // checks if the max axis value has more/equal than 7 characters
  return String(maxAxisValue.toFixed()).length >= 7 ? 110 : 90;
};

const baseClass = 'pn-domain-community-statistic';

const communityStatisticsCopy = {
  differenceIndicator:
    "This number represents the change in this indicator's value since the previous measurement.",
  dataUnavailable: 'Data unavailable',
  chartWell: (description: string | null, fipsName?: string) => {
    return (
      <>
        This chart illustrates the change over time in
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  noChartText: (description: string | null, fipsName?: string) => {
    return (
      <>
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  indicatorMetaData: (
    source: string | null,
    recordedAt: Date | null,
    dateLevel: DateLevel | null
  ) => {
    return (
      <div className={`${baseClass}-indicator-metadata font-size-xs`}>
        <div>
          Data from <span className="font-weight-bold">{source}</span>
        </div>
        <div>
          In{' '}
          <span className="font-weight-bold">
            {dateLevel === DateLevel.YEAR
              ? moment.utc(recordedAt).format(YEAR_FORMAT)
              : moment.utc(recordedAt).format(MMMM_YEAR_FORMAT)}
          </span>
        </div>
      </div>
    );
  },
};

export const CommunityStatisticsCard: React.FC<Props> = (p) => {
  const communityStatisticsId = useId();
  const isInEmbedApp = React.useContext(EmbedContext);
  const ref = React.useRef<HTMLDivElement>(null);
  const generateImage = useGenerateImage(ref, `${p.variable.label} indicator`);
  const { width } = useWindowSize();

  const maxXAxisLables = wrap(() => {
    switch (true) {
      case width < 576:
        return 3;
      case width < 992:
        return 5;
      case width < 1200:
        return 10;
      default:
        return;
    }
  });
  const imageDownloadEnabled = !p.embedContext && !isInEmbedApp;
  const embeddableTrackEnabled =
    useFlagEnabled(KnownFlag.EMBEDDABLE_TRACK_DATA) &&
    !p.canNotManageDataPoints &&
    p.embedContext !== EmbedDataPointContext.VISUALIZATION;

  if (!p.variable.areasData.length) {
    return (
      <div className={`${baseClass} ${p.className || ''} p-3`}>
        <div className="font-weight-bold font-size-lg text-gray-40">
          {communityStatisticsCopy.dataUnavailable}
        </div>
      </div>
    );
  }

  const variable = p.variable;
  const demographicSegment = variable.demographicSegment;
  const isYearDate = variable.dateLevel === DateLevel.YEAR;

  const sortedValues = _.reverse(
    _.sortBy(
      variable.areasData.find((ad) => ad.fipsArea.id === p.currentFips.id)
        ?.performanceData,
      ({ recordedAt }) => recordedAt
    )
  );
  const recentValue =
    sortedValues.length && sortedValues[0].value !== null ? sortedValues[0] : null;

  const dataPoints = sortedValues.map((v) => {
    return {
      time: new Date(
        v.recordedAt.getUTCFullYear(),
        isYearDate ? 0 : v.recordedAt.getUTCMonth(),
        1
      ),
      value: v.value,
      formattedValue: formatIndicatorValue(v.value, p.variable.valueType),
    } as TimeBreakdown['data'][number];
  });
  const isMultiplePointsData = dataPoints.length > 1;
  const values = sortedValues.map(({ value }) => value);

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const calculatedMax =
    maxValue + (maxValue < 0 ? maxValue * -1 : maxValue) * 0.15 + 1;
  const calculatedMin =
    minValue - (minValue < 0 ? minValue * -1 : minValue) * 0.15 - 1;

  const yMinValue = calculatedMin < 0 && minValue >= 0 ? 0 : calculatedMin;
  const yMaxValue =
    variable.valueType === AnalyticsValueType.PERCENT
      ? calculatedMax > 100
        ? 100
        : calculatedMax
      : calculatedMax;

  const benchmarkValue = recentValue
    ? getFilteredBenchmarkValue(recentValue, p.benchmarkFilter)
    : null;

  const footer =
    p.currentFips.id !== NationalDataFips
      ? getFilteredBenchmarkFooter(p.benchmarkFilter)
      : undefined;
  const allAreas = p.comparisonGroupId
    ? variable.areasData.map((ad) => ad.fipsArea.id)
    : [p.currentFips.id];
  return (
    <div
      className={`${baseClass} ${p.className || ''} p-3 ${
        p.embedContext === EmbedDataPointContext.VISUALIZATION ? 'border-0' : ''
      }`}
      id={communityStatisticsId}
      ref={ref}
    >
      <div className="d-flex justify-content-between">
        <div
          className={`${baseClass}-title-container d-flex flex-wrap justify-content-between align-items-start`}
        >
          <div className="d-flex align-items-start flex-column justify-content-start">
            <div className={`font-weight-bold font-size-lg mr-4`}>
              {variable.label}
            </div>
            {benchmarkValue && (
              <BenchmarkIndicator
                benchmarkValue={analyticsBenchmarkToClient(benchmarkValue)}
                className={`${baseClass}-benchmark-indicator mb-1`}
                expandMode={ExpandMode.ALWAYS_EXPANDED}
              />
            )}
          </div>

          {p.displayType === VariableDisplayType.OLDER_ADULTS && (
            <div
              className={`${baseClass}-demographic-segment d-flex d-row flex-wrap justify-content-start`}
            >
              <Pill
                className="segment"
                key={demographicSegment}
                type={PillTypes.FACET}
              >
                {demographicSegmentLabel(demographicSegment)}
              </Pill>
            </div>
          )}
        </div>
        <div className="d-flex">
          {embeddableTrackEnabled &&
          p.saveVisualization &&
          recentValue &&
          p.currentFips ? (
            <DomainCardOptionsMenu
              benchmarkFilter={p.benchmarkFilter}
              canNotManageDataPoints={p.canNotManageDataPoints}
              currentFips={p.currentFips.id}
              disableSaveVisualization={p.disableSaveVisualization}
              domain={p.domain}
              embeddableTrackEnabled={embeddableTrackEnabled}
              events={{ generateImage, saveVisualization: p.saveVisualization }}
              groupFips={allAreas}
              imageDownloadEnabled={imageDownloadEnabled}
              recentValue={recentValue}
              variable={variable}
              visualizationType={VisualizationType.LINE}
            />
          ) : (
            imageDownloadEnabled && (
              <DownloadImageButton
                buttonClassName={`save-image-track-${
                  p.domain
                }-community-statistics-${variable.name.replace(/\s/g, '')}`}
                imageRef={ref}
                name={`${variable.label} Indicator`}
              />
            )
          )}
          <CollapseBtn
            action={() => p.toggleExpanded(variable.id)}
            ariaControls={communityStatisticsId}
            expanded={!!p.expanded}
          />
        </div>
      </div>
      {p.showFooter && (
        <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
      )}
      <div
        className={`${baseClass}-data d-flex flex-row flex-wrap justify-content-between align-items-center`}
      >
        {recentValue ? (
          <>
            <div className={`d-flex flex-row align-items-center`}>
              <div
                className={`${baseClass}-indicator-value font-weight-bold mr-2 text-liberty`}
              >
                {formatIndicatorValue(recentValue.value, variable.valueType)}
              </div>
              <div className="font-size-sm text-gray-40">{variable.suffix}</div>
              {recentValue.previousValue && (
                <DifferenceIndicator
                  className="ml-2 cursor-pointer"
                  difference={recentValue.value - recentValue.previousValue?.value}
                  isNeutralPalette
                  tooltip={communityStatisticsCopy.differenceIndicator}
                  tooltipId={`difference-indicator-information-${variable.name}`}
                  valueType={variable.valueType}
                  variableDateLevel={variable.dateLevel}
                />
              )}
            </div>

            {communityStatisticsCopy.indicatorMetaData(
              variable.source,
              recentValue.recordedAt,
              variable.dateLevel
            )}
          </>
        ) : (
          <div
            className={`${baseClass}-data-unavailable font-weight-bold text-gray-40`}
          >
            {communityStatisticsCopy.dataUnavailable}
          </div>
        )}
      </div>
      {!!p.expanded && (
        <div className="mt-2">
          {isMultiplePointsData && (
            <LineChart
              bodyClassName={`${baseClass}-line-chart`}
              hasIndicators
              hideAxisLines
              hideMonthTick={isYearDate}
              hideXTick
              innerMargin={{
                top: 20,
                right: 40,
                bottom: 60,
                left: calculateChartMarginLeft(calculatedMax),
              }}
              lineColor={COLORS_LIBERTY_HEX}
              maxXDataPoints={maxXAxisLables}
              times={{
                interval: isYearDate
                  ? TimeBreakdownInterval.YEAR
                  : TimeBreakdownInterval.MONTH,
                data: dataPoints,
                isIndicator: true,
              }}
              transition={{
                delay: 0,
                duration: 700,
              }}
              uniqueId={variable.id}
              xMaxTicksQuantity={10}
              yMaxValue={yMaxValue}
              yMinValue={yMinValue}
            />
          )}
          <Well
            className="mb-0"
            noTopMargin
            type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
          >
            {isMultiplePointsData
              ? communityStatisticsCopy.chartWell(
                  variable.description,
                  recentValue?.fipsArea.name
                )
              : communityStatisticsCopy.noChartText(
                  variable.description,
                  recentValue?.fipsArea.name
                )}
          </Well>
        </div>
      )}
      <DownloadImageFooter footer={footer} />
      {p.embedContext === EmbedDataPointContext.VISUALIZATION && (
        <EmbedLogoFooter className={p.expanded ? 'pt-2' : ''} />
      )}
    </div>
  );
};

interface DomainCardOptionsMenuProps {
  readonly imageDownloadEnabled: boolean;
  readonly currentFips: string;
  readonly groupFips: readonly string[];
  readonly embeddableTrackEnabled: boolean;
  readonly recentValue: TrackDatum;
  readonly variable: TrackVariable;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly domain: AnalyticsDomain;
  readonly visualizationType: VisualizationType;
  readonly events: {
    readonly generateImage?: () => Promise<void>;
    readonly saveVisualization?: (
      clientInput: CreateSavedVisualizationInputWithMultipleFips
    ) => Promise<void>;
  };
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly className?: string;
  readonly comparisonGroupId?: string;
}

export const DomainCardOptionsMenu: React.FC<DomainCardOptionsMenuProps> = (
  props
) => {
  const { generateImage, saveVisualization } = props.events;
  return (
    <BsDropdown className={`no-show-in-image ${props.className || ''}`}>
      <EventPropagationStopper>
        <div className={`${baseClass}-toggle-wrapper`}>
          <BsDropdown.Toggle
            className={`${baseClass}-action-toggle p-0 m-0`}
            variant="link"
          >
            <MaterialIcon
              className={`${baseClass}-action-toggle-icon`}
              icon={MaterialIconName.MORE_VERT}
            />
          </BsDropdown.Toggle>
        </div>
      </EventPropagationStopper>

      <BsDropdown.Menu alignRight>
        <EventPropagationStopper>
          {props.imageDownloadEnabled && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-image-track-${
                props.domain
              }-community-statistics-${props.variable.name.replace(/\s/g, '')}`}
              onClick={generateImage}
            >
              <div>{DROPDOWN_COPY.image}</div>
            </BsDropdown.Item>
          )}
          {!props.canNotManageDataPoints &&
            props.recentValue &&
            props.embeddableTrackEnabled &&
            saveVisualization && (
              <Tooltip
                className={`${baseClass}-tooltip font-weight-normal`}
                content=""
                disable={!props.disableSaveVisualization}
                htmlContent={
                  <div>
                    You have exceeded your maximum number of active saved data
                    points. Edit your{' '}
                    <AppLink
                      className="text-white"
                      to={ClientUrlUtils.admin.savedDataPoints.path({
                        tab: SavedVisualizationFilterStates.ACTIVE,
                      })}
                    >
                      Saved Data Points
                    </AppLink>{' '}
                    to make room for more.
                  </div>
                }
                id="visualization-explainer"
                place="bottom"
              >
                <BsDropdown.Item
                  className={`${baseClass}-dropDown-item save-data-point`}
                  disabled={props.disableSaveVisualization}
                  onClick={async () => {
                    await saveVisualization({
                      baseFips: props.currentFips,
                      recordedAt: props.recentValue.recordedAt,
                      benchmarkFilter: props.benchmarkFilter,
                      state: ActivationState.ACTIVE,
                      variableId: props.variable.id,
                      visualizationType: props.visualizationType,
                      groupFips: props.groupFips,
                      comparisonGroupId: props.comparisonGroupId,
                    });
                  }}
                >
                  <div>{DROPDOWN_COPY.dataPont}</div>
                </BsDropdown.Item>
              </Tooltip>
            )}
        </EventPropagationStopper>
      </BsDropdown.Menu>
    </BsDropdown>
  );
};

const DROPDOWN_COPY = {
  image: 'Save as Image',
  dataPont: 'Save Data Point',
};
