import React from 'react';
import moment from 'moment';
import { toPng } from 'html-to-image';

const IMAGE_CLASSES_TO_EXCLUDE = ['no-show-in-image'];

const filter = (node: HTMLElement) => {
  const classesToExclude = IMAGE_CLASSES_TO_EXCLUDE;
  return !classesToExclude.some((className) => node.classList?.contains(className));
};

export const downloadSnapshot = (imageUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function useGenerateImage(
  ref: React.RefObject<HTMLElement>,
  imageName?: string,
  afterGenerationAction?: () => any,
  parentClassType?: '-wide',
  beforeGenerationAction?: () => any
) {
  return React.useCallback(async () => {
    if (ref.current === null) {
      return;
    }
    const parentClass = `image-generation-parent${parentClassType ?? ''}`;
    ref.current.classList.add(parentClass);
    const timeStamp = moment().format('MMMM Do YYYY');
    try {
      if (beforeGenerationAction) beforeGenerationAction();
      const dataPngUrl = await buildPng(ref.current);
      ref.current.classList.remove(parentClass);
      downloadSnapshot(dataPngUrl, `Polco ${imageName + ' - '}${timeStamp}.png`);
    } catch (error) {
      console.error(error);
    } finally {
      if (afterGenerationAction) afterGenerationAction();
      ref.current.classList.remove(parentClass); // An extra remove just in case
    }
  }, [
    ref,
    parentClassType,
    imageName,
    afterGenerationAction,
    beforeGenerationAction,
  ]);
}

const buildPng = async (htmlElement: HTMLElement) => {
  // There is a bug in Safari that the toPng promise resolves before the entire image has been generated
  // This causes downloaded images to be missing random elements
  // This looping attempts to give extra time to Safari to generate all of the image
  // It terminates when it sees the image size grow, indicating full completion, or after a set number of loops
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  let dataUrl = '';
  let i = 0;
  let maxAttempts;
  if (isSafari) {
    maxAttempts = 5;
  } else {
    maxAttempts = 1;
  }
  const dataUrlLengthPerLoop = [];

  while (i < maxAttempts) {
    dataUrl = await toPng(htmlElement, {
      cacheBust: true,
      quality: 1,
      style: {
        background: 'white',
        marginTop: '0',
        height: 'fit-content',
        width: 'fit-content',
        minWidth: '100%',
      },
      filter,
    });
    i += 1;
    dataUrlLengthPerLoop[i] = dataUrl.length;

    if (dataUrl.length > dataUrlLengthPerLoop[i - 1]) {
      break;
    }
  }

  return dataUrl;
};
