import React from 'react';
import {
  AnalyticsDomain,
  BenchmarkFilter,
  ChartType,
  FipsAreaWithShortName,
  NationalDataFips,
  TrackAreaMode,
  TrackVariableWithDistributions,
  VariableDisplayType,
} from 'core';
import { DomainCardContent } from 'client/shared/components/domain-card';
import _ from 'lodash';
import { IndexScoreChart } from 'client/shared/components/index-score-chart';
import { DomainIndicatorsGroupWrapper } from 'client/shared/components/domain-indicators-group/domain-indicators-group-wrapper';
import { IndexScoreChartContainer } from 'client/shared/containers/index-score-chart-container';
import { EmbedDataPointContext } from 'client/shared/components/visualization-picker';

interface GroupIndexScoresProps {
  readonly comparisonGroupId?: string;
  readonly trackVariables: readonly TrackVariableWithDistributions[];
  readonly currentFips?: FipsAreaWithShortName;
  readonly domain: AnalyticsDomain;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly chartType: ChartType;
  readonly setChartType: React.Dispatch<React.SetStateAction<ChartType>>;
  readonly displayType: VariableDisplayType;
  readonly embedContext?: EmbedDataPointContext;
}

export const GroupIndexScoresContainer: React.FC<GroupIndexScoresProps> = (p) => {
  const variable = p.trackVariables[0];
  const areasData = p.trackVariables.flatMap((v) => {
    return v.areasData;
  });
  const isNationalData = p.currentFips?.id === NationalDataFips;
  const viewerValues =
    areasData.find((ad) => ad.fipsArea.id === p.currentFips?.id)?.performanceData ??
    [];
  const sortedValues = _.reverse(
    _.sortBy(viewerValues, ({ recordedAt }) => recordedAt)
  );
  const recentValue =
    sortedValues.length && sortedValues[0].value !== null ? sortedValues[0] : null;
  const allAreas = areasData.map((ad) => ad.fipsArea);

  let chart;
  if (p.chartType === ChartType.HISTOGRAM) {
    chart = (
      <IndexScoreChart
        benchmarkFilter={p.benchmarkFilter}
        comparisonGroupId={p.comparisonGroupId ?? null}
        distributions={recentValue?.distributions ?? []}
        hideBorder
        indexScoreData={recentValue ?? undefined}
        maintainAspectRatio
        variable={variable}
      />
    );
  } else if (p.chartType === ChartType.LINE) {
    chart = (
      <DomainIndicatorsGroupWrapper
        fipsAreas={allAreas}
        isEmbedded={!!p.embedContext}
        label={null}
        showLineChart={true}
        trackAreaData={areasData}
        variable={variable}
      />
    );
  }

  return (
    <div>
      <DomainCardContent
        className="mb-3"
        displayType={p.displayType}
        domain={p.domain}
        indexScoreData={recentValue ?? undefined}
        variable={variable}
      />
      {p.children}
      <IndexScoreChartContainer
        areaMode={TrackAreaMode.LOCAL}
        chartType={p.chartType}
        setChartType={p.setChartType}
        showMyCommunity={!isNationalData}
      >
        {chart}
      </IndexScoreChartContainer>
    </div>
  );
};
