import * as React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useId } from 'react-use-id-hook';
import './styles.scss';
import {
  AppLink,
  EventPropagationStopper,
  ExpandMode,
  MaterialIcon,
  MaterialIconName,
  Pill,
  PillTypes,
  Well,
  WellType,
} from 'client/shared/components/base';
import { BenchmarkIndicator } from 'client/shared/components/benchmark-indicator';
import {
  MMMM_YEAR_FORMAT,
  YEAR_FORMAT,
  AnalyticsDomain,
  ActivationState,
  VisualizationType,
  SavedVisualizationFilterStates,
  VariableDisplayType,
  TrackDatum,
  TrackVariableMetadata,
  TrackVariable,
} from 'core';
import { DifferenceIndicator } from 'client/shared/components/difference-indicator';
import { CollapseBtn } from 'client/shared/components/base/collapse-btn';
import {
  BenchmarkFilter,
  DateLevel,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  analyticsBenchmarkToClient,
  formatIndicatorValue,
  getFilteredBenchmarkFooter,
  getFilteredBenchmarkValue,
} from 'client/shared/core/performance-data';
import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { EmbedContext } from 'client/shared/contexts/embed-context';
import { EmbedDataPointContext } from 'client/shared/components/visualization-picker';
import { DownloadImageButton } from 'client/shared/components/download-image';
import { EmbedLogoFooter } from 'client/shared/components/embed-logo-footer';
import { Tooltip } from 'client/shared/components/tooltip';
import { DomainIndicatorsGroupWrapper } from 'client/shared/components/domain-indicators-group/domain-indicators-group-wrapper';
import { demographicSegmentLabel } from 'client/shared/components/domain-card-indicator';
import { useFlagEnabled, KnownFlag } from 'client/shared/contexts/flags-context';
import { useGenerateImage } from 'client/shared/hooks';

export interface Props {
  readonly benchmarkFilter: BenchmarkFilter;
  readonly className?: string;
  readonly domain: AnalyticsDomain;
  readonly expanded?: boolean;
  readonly toggleExpanded: (variableId: string) => void;
  readonly embedContext?: EmbedDataPointContext;
  readonly showFooter?: boolean;
  readonly saveVisualization?: (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => Promise<void>;
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly indicator: TrackVariable;
  readonly currentFips?: string;
  readonly displayType: VariableDisplayType;
  readonly comparisonGroupId?: string;
}

const baseClass = 'pn-domain-community-statistic';

const communityStatisticsCopy = {
  differenceIndicator:
    "This number represents the change in this indicator's value since the previous measurement.",
  dataUnavailable: 'Data unavailable',
  chartWell: (description: string | null, fipsName?: string) => {
    return (
      <>
        This chart illustrates the change over time in
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  noChartText: (description: string | null, fipsName?: string) => {
    return (
      <>
        <span className="font-weight-bold"> {description}</span>
        {!!fipsName && (
          <>
            {' for '}
            <span className="font-weight-bold">{fipsName}</span>
          </>
        )}
        .
      </>
    );
  },
  indicatorMetaData: (
    source: string | null,
    recordedAt: Date | null,
    dateLevel: DateLevel | null
  ) => {
    return (
      <div className={`${baseClass}-indicator-metadata font-size-xs`}>
        <div>
          Data from <span className="font-weight-bold">{source}</span>
        </div>
        <div>
          In{' '}
          <span className="font-weight-bold">
            {dateLevel === DateLevel.YEAR
              ? moment.utc(recordedAt).format(YEAR_FORMAT)
              : moment.utc(recordedAt).format(MMMM_YEAR_FORMAT)}
          </span>
        </div>
      </div>
    );
  },
};

export const GroupIndicatorCard: React.FC<Props> = React.memo((p) => {
  const communityStatisticsId = useId();
  const isInEmbedApp = React.useContext(EmbedContext);
  const ref = React.useRef<HTMLDivElement>(null);
  const generateImage = useGenerateImage(ref, `${p.indicator.label} indicator`);

  const imageDownloadEnabled = !p.embedContext && !isInEmbedApp;
  const embeddableTrackEnabled =
    useFlagEnabled(KnownFlag.EMBEDDABLE_TRACK_DATA) &&
    !p.canNotManageDataPoints &&
    p.embedContext !== EmbedDataPointContext.VISUALIZATION;

  const variable = p.indicator;

  const viewerValues =
    variable.areasData.find((ad) => ad.fipsArea.id === p.currentFips)
      ?.performanceData ?? [];

  const sortedValues = _.reverse(
    _.sortBy(viewerValues, ({ recordedAt }) => recordedAt)
  );

  const recentValue =
    sortedValues.length && sortedValues[0].value !== null ? sortedValues[0] : null;
  const benchmarkValue = recentValue
    ? getFilteredBenchmarkValue(recentValue, p.benchmarkFilter)
    : null;

  const footer = getFilteredBenchmarkFooter(p.benchmarkFilter);
  const allAreas = variable.areasData.map((ad) => ad.fipsArea);

  return (
    <div
      className={`${baseClass} ${p.className || ''} p-3 ${
        p.embedContext === EmbedDataPointContext.VISUALIZATION ? 'border-0' : ''
      }`}
      id={communityStatisticsId}
      ref={ref}
    >
      <div className="d-flex justify-content-between">
        <div
          className={`${baseClass}-title-container d-flex flex-wrap justify-content-between align-items-start`}
        >
          <div className="d-flex align-items-start flex-column justify-content-start">
            <div className={`font-weight-bold font-size-lg mr-4`}>
              {variable.label}
            </div>
            {benchmarkValue && (
              <BenchmarkIndicator
                benchmarkValue={analyticsBenchmarkToClient(benchmarkValue)}
                className={`${baseClass}-benchmark-indicator mb-1`}
                expandMode={ExpandMode.ALWAYS_EXPANDED}
              />
            )}
          </div>

          {p.displayType === VariableDisplayType.OLDER_ADULTS && (
            <div
              className={`${baseClass}-demographic-segment d-flex d-row flex-wrap justify-content-start`}
            >
              <Pill
                className="segment"
                key={variable.demographicSegment}
                type={PillTypes.FACET}
              >
                {demographicSegmentLabel(variable.demographicSegment)}
              </Pill>
            </div>
          )}
        </div>
        <div className="d-flex">
          {embeddableTrackEnabled &&
          p.saveVisualization &&
          recentValue &&
          !!p.currentFips ? (
            <DomainCardOptionsMenu
              allFips={allAreas.map((a) => a.id)}
              benchmarkFilter={p.benchmarkFilter}
              canNotManageDataPoints={p.canNotManageDataPoints}
              currentFips={p.currentFips}
              disableSaveVisualization={p.disableSaveVisualization}
              domain={p.domain}
              embeddableTrackEnabled={embeddableTrackEnabled}
              events={{ generateImage, saveVisualization: p.saveVisualization }}
              groupId={p.comparisonGroupId}
              imageDownloadEnabled={imageDownloadEnabled}
              recentValue={recentValue}
              variable={variable}
              visualizationType={VisualizationType.LINE}
            />
          ) : (
            imageDownloadEnabled && (
              <DownloadImageButton
                buttonClassName={`save-image-track-${
                  p.domain
                }-community-statistics-${variable.name.replace(/\s/g, '')}`}
                imageRef={ref}
                name={`${variable.label} Indicator`}
              />
            )
          )}
          <CollapseBtn
            action={() => p.toggleExpanded(variable.id)}
            ariaControls={communityStatisticsId}
            expanded={!!p.expanded}
          />
        </div>
      </div>
      {p.showFooter && (
        <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
      )}
      <div
        className={`${baseClass}-data d-flex flex-row flex-wrap justify-content-between align-items-center`}
      >
        {recentValue ? (
          <>
            <div className={`d-flex flex-row align-items-center`}>
              <div
                className={`${baseClass}-indicator-value font-weight-bold mr-2 text-liberty`}
              >
                {formatIndicatorValue(recentValue.value, variable.valueType)}
              </div>
              <div className="font-size-sm text-gray-40">{variable.suffix}</div>
              {recentValue.previousValue && (
                <DifferenceIndicator
                  className="ml-2 cursor-pointer"
                  difference={recentValue.value - recentValue.previousValue?.value}
                  isNeutralPalette
                  tooltip={communityStatisticsCopy.differenceIndicator}
                  tooltipId={`difference-indicator-information-${variable.name}`}
                  valueType={variable.valueType}
                  variableDateLevel={variable.dateLevel}
                />
              )}
            </div>

            {communityStatisticsCopy.indicatorMetaData(
              variable.source,
              recentValue.recordedAt,
              variable.dateLevel
            )}
          </>
        ) : (
          <div
            className={`${baseClass}-data-unavailable font-weight-bold text-gray-40`}
          >
            {communityStatisticsCopy.dataUnavailable}
          </div>
        )}
      </div>
      <DomainIndicatorsGroupWrapper
        fipsAreas={allAreas}
        isEmbedded={!!p.embedContext}
        label={null}
        showLineChart={!!p.expanded}
        trackAreaData={variable.areasData}
        variable={{
          id: p.indicator.id,
          direction: p.indicator.direction,
          valueType: p.indicator.valueType,
          name: p.indicator.name,
          dateLevel: p.indicator.dateLevel,
          statisticType: p.indicator.statisticType,
        }}
      />
      {!!p.expanded && (
        <div className="mt-2">
          <Well
            className="mb-0"
            noTopMargin
            type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
          >
            {communityStatisticsCopy.chartWell(variable.description)}
          </Well>
        </div>
      )}
      <DownloadImageFooter footer={footer} />
      {p.embedContext === EmbedDataPointContext.VISUALIZATION && (
        <EmbedLogoFooter className={p.expanded ? 'pt-2' : ''} />
      )}
    </div>
  );
});

interface DomainCardOptionsMenuProps {
  readonly currentFips: string;
  readonly imageDownloadEnabled: boolean;
  readonly embeddableTrackEnabled: boolean;
  readonly recentValue: TrackDatum;
  readonly variable: TrackVariableMetadata;
  readonly allFips: readonly string[];
  readonly groupId?: string;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly domain: AnalyticsDomain;
  readonly visualizationType: VisualizationType;
  readonly events: {
    readonly generateImage?: () => Promise<void>;
    readonly saveVisualization?: (
      clientInput: CreateSavedVisualizationInputWithMultipleFips
    ) => Promise<void>;
  };
  readonly disableSaveVisualization?: boolean;
  readonly canNotManageDataPoints?: boolean;
  readonly className?: string;
}

export const DomainCardOptionsMenu: React.FC<DomainCardOptionsMenuProps> = (
  props
) => {
  const { generateImage, saveVisualization } = props.events;
  return (
    <BsDropdown className={`no-show-in-image ${props.className || ''}`}>
      <EventPropagationStopper>
        <div className={`${baseClass}-toggle-wrapper`}>
          <BsDropdown.Toggle
            className={`${baseClass}-action-toggle p-0 m-0`}
            variant="link"
          >
            <MaterialIcon
              className={`${baseClass}-action-toggle-icon`}
              icon={MaterialIconName.MORE_VERT}
            />
          </BsDropdown.Toggle>
        </div>
      </EventPropagationStopper>

      <BsDropdown.Menu alignRight>
        <EventPropagationStopper>
          {props.imageDownloadEnabled && (
            <BsDropdown.Item
              className={`${baseClass}-dropDown-item save-image-track-${
                props.domain
              }-community-statistics-${props.variable.name.replace(/\s/g, '')}`}
              onClick={generateImage}
            >
              <div>{DROPDOWN_COPY.image}</div>
            </BsDropdown.Item>
          )}
          {!props.canNotManageDataPoints &&
            props.recentValue &&
            props.embeddableTrackEnabled &&
            saveVisualization && (
              <Tooltip
                className={`${baseClass}-tooltip font-weight-normal`}
                content=""
                disable={!props.disableSaveVisualization}
                htmlContent={
                  <div>
                    You have exceeded your maximum number of active saved data
                    points. Edit your{' '}
                    <AppLink
                      className="text-white"
                      to={ClientUrlUtils.admin.savedDataPoints.path({
                        tab: SavedVisualizationFilterStates.ACTIVE,
                      })}
                    >
                      Saved Data Points
                    </AppLink>{' '}
                    to make room for more.
                  </div>
                }
                id="visualization-explainer"
                place="bottom"
              >
                <BsDropdown.Item
                  className={`${baseClass}-dropDown-item save-data-point`}
                  disabled={props.disableSaveVisualization}
                  onClick={async () => {
                    await saveVisualization({
                      baseFips: props.currentFips,
                      recordedAt: props.recentValue.recordedAt,
                      benchmarkFilter: props.benchmarkFilter,
                      state: ActivationState.ACTIVE,
                      variableId: props.variable.id,
                      visualizationType: props.visualizationType,
                      groupFips: props.allFips,
                      comparisonGroupId: props.groupId,
                    });
                  }}
                >
                  <div>{DROPDOWN_COPY.dataPont}</div>
                </BsDropdown.Item>
              </Tooltip>
            )}
        </EventPropagationStopper>
      </BsDropdown.Menu>
    </BsDropdown>
  );
};

const DROPDOWN_COPY = {
  image: 'Save as Image',
  dataPont: 'Save Data Point',
};
