import _ from 'lodash';
import React from 'react';
import {
  LineChart,
  TimeBreakdown,
  TimeBreakdownInterval,
} from 'client/shared/components/data-viz-d3/line-chart';
import { COLORS_LIBERTY_HEX } from 'client/shared/core/colors';
import { IndexScoreChart } from 'client/shared/components/index-score-chart';
import './styles.scss';
import {
  BenchmarkFilter,
  DateLevel,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  ChartType,
  FipsArea,
  TrackAreaMode,
  TrackDatumWithDistributions,
  TrackVariableWithDistributions,
  getMostRecentDatumCurrentPub,
} from 'core';
import { IndexScoreChartContainer } from '../index-score-chart-container';

const baseClass = 'pn-domain-chart-container';

interface Props {
  readonly areaMode: TrackAreaMode;
  readonly indexScore: TrackVariableWithDistributions;
  readonly currentFips: FipsArea;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly defaultChart: ChartType;
  readonly setChart: React.Dispatch<React.SetStateAction<ChartType>>;
}

export const DomainChartContainer: React.FC<Props> = (props) => {
  const indexScoreData = getMostRecentDatumCurrentPub<
    TrackDatumWithDistributions,
    TrackVariableWithDistributions
  >(props.currentFips, props.indexScore);

  const isNationalData = props.areaMode === TrackAreaMode.NATIONAL;
  let chart;
  if (props.defaultChart === ChartType.HISTOGRAM) {
    chart = (
      <IndexScoreChart
        benchmarkFilter={props.benchmarkFilter}
        comparisonGroupId={null}
        distributions={indexScoreData?.distributions ?? []}
        hideBorder
        indexScoreData={isNationalData ? undefined : indexScoreData}
        maintainAspectRatio
        variable={props.indexScore}
      />
    );
  } else if (props.defaultChart === ChartType.LINE) {
    const isYearDate = props.indexScore.dateLevel === DateLevel.YEAR;
    const times: TimeBreakdown = {
      interval: isYearDate
        ? TimeBreakdownInterval.YEAR
        : TimeBreakdownInterval.MONTH,
      data: _(
        props.indexScore.areasData.find(
          (ad) => ad.fipsArea.id === props.currentFips.id
        )?.performanceData
      )
        .sortBy(({ recordedAt }) => recordedAt)
        .reverse()
        .map((indexScoreParameter) => ({
          time: new Date(
            indexScoreParameter.recordedAt.getUTCFullYear(),
            isYearDate ? 0 : indexScoreParameter.recordedAt.getUTCMonth(),
            1
          ),
          value: Number(indexScoreParameter.value.toFixed()),
        }))
        .value(),
      isIndicator: false,
    };

    chart = (
      <LineChart
        bodyClassName={`${baseClass}-line-chart`}
        hasIndicators
        hideAxisLines
        hideMonthTick={isYearDate}
        hideXTick
        innerMargin={{
          top: 20,
          right: 40,
          bottom: 40,
          left: 80,
        }}
        lineColor={COLORS_LIBERTY_HEX}
        times={times}
        transition={{
          delay: 0,
          duration: 700,
        }}
        uniqueId={props.indexScore.id}
        yMaxValue={100}
        yMinValue={0}
      />
    );
  }

  return (
    <IndexScoreChartContainer
      areaMode={props.areaMode}
      chartType={props.defaultChart}
      setChartType={props.setChart}
      showMyCommunity={!isNationalData}
    >
      {chart}
    </IndexScoreChartContainer>
  );
};
