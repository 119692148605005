import {
  AnalyticsDomain,
  AnalyticsSubdomain,
} from 'client/shared/graphql-client/graphql-operations.g';
import { FeatureSettingType } from 'core';

export const domainLabels: Record<AnalyticsDomain, string> = {
  SAFETY: 'Safety',
  ECONOMY: 'Economy',
  COMMUNITY_DESIGN: 'Community Design',
  HEALTH_AND_WELLNESS: 'Health and Wellness',
  INCLUSIVITY_AND_ENGAGEMENT: 'Inclusivity & Engagement',
  EDUCATION_ARTS_CULTURE: 'Education, Arts & Culture',
  GOVERNANCE: 'Governance',
  COMMUNITY_LIVABILITY: 'Community Livability',
  COMMUNITY_CHARACTERISTICS: 'Community Characteristics',
  MOBILITY: 'Mobility',
  NATURAL_ENVIRONMENT: 'Natural Environment',
  PARKS_AND_RECREATION: 'Parks and Recreation',
  UTILITIES: 'Utilities',
};

export const domainLabelsToFeatureSetting: Record<
  Exclude<
    AnalyticsDomain,
    'GOVERNANCE' | 'COMMUNITY_LIVABILITY' | 'COMMUNITY_CHARACTERISTICS'
  >,
  FeatureSettingType
> = {
  SAFETY: FeatureSettingType.TRACK_SAFETY,
  ECONOMY: FeatureSettingType.TRACK_ECONOMY,
  COMMUNITY_DESIGN: FeatureSettingType.TRACK_COMMUNITY_DESIGN,
  HEALTH_AND_WELLNESS: FeatureSettingType.TRACK_HEALTH_AND_WELLNESS,
  INCLUSIVITY_AND_ENGAGEMENT: FeatureSettingType.TRACK_INCLUSIVITY_AND_ENGAGEMENT,
  EDUCATION_ARTS_CULTURE: FeatureSettingType.TRACK_EDUCATION_ARTS_AND_CULTURE,
  MOBILITY: FeatureSettingType.TRACK_MOBILITY,
  NATURAL_ENVIRONMENT: FeatureSettingType.TRACK_NATURAL_ENVIRONMENT,
  PARKS_AND_RECREATION: FeatureSettingType.TRACK_PARKS_AND_RECREATION,
  UTILITIES: FeatureSettingType.TRACK_UTILITIES,
};

export const subdomainLabels: Record<AnalyticsSubdomain, string> = {
  EQUITY: 'Equity',
  INCOME: 'Income',
  SAFETY: 'Safety',
  ECONOMY: 'Economy',
  HOUSING: 'Housing',
  LAND_USE: 'Land Use',
  MOBILITY: 'Mobility',
  EDUCATION: 'Education',
  LIBRARIES: 'Libraries',
  UTILITIES: 'Utilities',
  EMPLOYMENT: 'Employment',
  GOVERNANCE: 'Governance',
  HEALTHCARE: 'Healthcare',
  FIRE_EMS: 'Fire and EMS',
  FOOD_SECURITY: 'Food Security',
  LAW_ENFORCEMENT: 'Law Enforcement',
  RESIDENT_HEALTH: 'Resident Health',
  ARTS_CULTURE: 'Arts and culture',
  BUSINESS_VITALITY: 'Business vitality',
  HEALTHY_LIFESTYLES: 'Healthy Lifestyles',
  POWER: 'Power',
  COMMUNITY_LIVABILITY: 'Community Livability',
  ENVIRONMENTAL_HEALTH: 'Environmental Health',
  MENTAL_HEALTH: 'Mental Health',
  PARKS: 'Parks',
  RECREATION: 'Recreations',
  BROADBAND: 'Broadband',
  AUTO: 'Auto travel',
  DIVERSITY_INCLUSION: 'Diversity and Inclusion',
  WATER_SEWER: 'Water/Sewer',
  COMMUNITY_CHARACTERISTICS: 'Community Characteristics',
  ALTERNATE_MODES: 'Alternate Modes',
  ENGAGEMENT_CIVIC_SOCIAL: 'Engagement (civic and social)',
  SOLID_WASTE_MANAGEMENT: 'Solid Waste Management',
  CLIMATE: 'Climate',
  COMMUNITY_CLEANLINESS: 'Community Cleanliness',
  FEELINGS_OF_SAFETY: 'Feelings of Safety',
  PATHS_AND_TRAILS: 'Path and Trails',
  WELLNESS: 'Wellness',
  AIR_QUALITY: 'Air Quality',
};

export const OrderedDashboardDomains: readonly AnalyticsDomain[] = [
  AnalyticsDomain.SAFETY,
  AnalyticsDomain.ECONOMY,
  AnalyticsDomain.MOBILITY,
  AnalyticsDomain.UTILITIES,
  AnalyticsDomain.COMMUNITY_DESIGN,
  AnalyticsDomain.HEALTH_AND_WELLNESS,
  AnalyticsDomain.EDUCATION_ARTS_CULTURE,
  AnalyticsDomain.INCLUSIVITY_AND_ENGAGEMENT,
  AnalyticsDomain.NATURAL_ENVIRONMENT,
  AnalyticsDomain.PARKS_AND_RECREATION,
];

export const domainFacets: Record<AnalyticsDomain, readonly string[]> = {
  SAFETY: ['Law Enforcement', 'Fire', 'EMS', 'Jails'],
  ECONOMY: ['Employment', 'Income', 'Business Vitality'],
  COMMUNITY_DESIGN: ['Land Use', 'Housing'],
  MOBILITY: ['Alternate Modes', 'Auto'],
  UTILITIES: ['Broadband', 'Power', 'Solid Waste Management', 'Water Sewer'],
  HEALTH_AND_WELLNESS: [
    'Resident Health',
    'Environmental Health',
    'Food Security',
    'Health Care',
    'Healthy Lifestyles',
    'Mental Health',
  ],
  INCLUSIVITY_AND_ENGAGEMENT: ['Engagement', 'Diversity & Inclusion', 'Equity'],
  EDUCATION_ARTS_CULTURE: ['Education', 'Libraries', 'Arts & Culture'],
  GOVERNANCE: [],
  COMMUNITY_LIVABILITY: [],
  COMMUNITY_CHARACTERISTICS: [],
  NATURAL_ENVIRONMENT: ['Climate', 'Community Cleanliness', 'Environmental Health'],
  PARKS_AND_RECREATION: ['Parks', 'Path and Trails', 'Recreation', 'Wellness'],
};

export const domainCardIndicatorVariables: Record<
  AnalyticsDomain,
  readonly string[]
> = {
  SAFETY: ['violent_crime_rate', 'property_crime_rate'],
  INCLUSIVITY_AND_ENGAGEMENT: ['percent_voters_voted', 'gini'],
  HEALTH_AND_WELLNESS: ['physical_health', 'food_insecurity_rate'],
  EDUCATION_ARTS_CULTURE: ['graduation_rate', 'library_visits_per_capita'],
  ECONOMY: ['unemployment_rate', 'gdp_per_capita'],
  COMMUNITY_DESIGN: ['job_access_score', 'housing_burden_rate'],
  GOVERNANCE: [],
  COMMUNITY_LIVABILITY: [],
  COMMUNITY_CHARACTERISTICS: [],
  MOBILITY: ['mean_travel_time', 'walkability'],
  NATURAL_ENVIRONMENT: ['air_particulate_matter', 'risk_score'],
  PARKS_AND_RECREATION: ['live_near_park', 'pct_access_to_exercise_opportunities'],
  UTILITIES: ['drinking_water_violations', 'pct_high_speed_internet'],
};

export const domainCardIndicatorVariablesOA: Record<
  AnalyticsDomain,
  readonly string[]
> = {
  SAFETY: ['wildfire_risk', 'violent_crime_rate'],
  INCLUSIVITY_AND_ENGAGEMENT: [
    'households_oa_householder_living_alone_pct_oa',
    'grandparent_responsible_grandchildren_pct_oa',
  ],
  HEALTH_AND_WELLNESS: ['ambulatory_difficulty_pct_oa', 'hpsa_score'],
  EDUCATION_ARTS_CULTURE: [
    'library_visits_per_capita',
    'bachelors_degree_attainment_oa',
  ],
  ECONOMY: ['employment_rate_oa', 'poverty_rate_oa'],
  COMMUNITY_DESIGN: [
    'percent_subsidized_housing_units',
    'geographical_mobility_same_house_pct_oa',
  ],
  GOVERNANCE: [],
  COMMUNITY_LIVABILITY: [],
  COMMUNITY_CHARACTERISTICS: [],
  MOBILITY: ['walkability', 'traffic_volume'],
  NATURAL_ENVIRONMENT: ['risk_score', 'unhealthy_sensitive_days'],
  PARKS_AND_RECREATION: ['live_near_park', 'pct_access_to_exercise_opportunities'],
  UTILITIES: ['internet_access_score', 'power_outages'],
};

export const OVERALL_CONFIDENCE_IN_GOVERNMENT = 'ncs_295';
export const GOVERNMENT_EXPENDITURES =
  'government_operating_expenditures_per_capita';
export const OVERALL_QUALITY_OF_SERVICES = 'ncs_202';
export const BACHELORS_DEGREE_ATTAINMENT = 'bachelors_degree_attainment';
export const MEDIAN_HOUSEHOLD_INCOME = 'median_household_income';
export const MEDIAN_AGE = 'median_age';
export const DISABILITY_PCT = 'disability_pct_oa';
export const TOTAL_POP_OA = 'total_pop_oa';
export const TOTAL_POP = 'total_pop';

export const indicatorVariableNames: Record<AnalyticsDomain, readonly string[]> = {
  SAFETY: ['ncs_320'],
  INCLUSIVITY_AND_ENGAGEMENT: ['ncs_900'],
  HEALTH_AND_WELLNESS: ['ncs_393'],
  EDUCATION_ARTS_CULTURE: ['ncs_399'],
  ECONOMY: ['ncs_169'],
  COMMUNITY_DESIGN: ['ncs_392'],
  GOVERNANCE: [OVERALL_CONFIDENCE_IN_GOVERNMENT, OVERALL_QUALITY_OF_SERVICES],
  COMMUNITY_LIVABILITY: ['ncs_316', 'ncs_301'],
  COMMUNITY_CHARACTERISTICS: [],
  MOBILITY: ['ncs_156'],
  NATURAL_ENVIRONMENT: ['ncs_96'],
  PARKS_AND_RECREATION: ['ncs_905'],
  UTILITIES: ['ncs_108'],
};

export enum DashboardBenchmarkSurveys {
  NCS = 'The National Community Survey',
  NLES = 'The National Law Enforcement Survey',
  NBS = 'The National Business Survey',
}

export const domainDescriptions: Record<AnalyticsDomain, string> = {
  SAFETY:
    'Safety is the cornerstone of an attractive community. Ensuring all residents feel secure and protected from crime, fire, and natural hazards is essential to a high quality of life.',
  ECONOMY:
    'Businesses, business networks and associations, and local governments all work together to help foster sustainable growth, create jobs, and promote a thriving local economy.',
  COMMUNITY_DESIGN:
    'A well-designed and well-planned built environment (residential, commercial, and public areas) improves communities by encouraging smart land use and zoning, ensuring that affordable housing is accessible to all, and providing attractive communal spaces.',
  MOBILITY:
    'The ease with which residents can move about their communities, whether for commuting, leisure, or recreation, plays a major role in the quality of life for all who live, work, and play in the community.',
  UTILITIES:
    'Services such as water, gas, electricity, and internet play a vital role in ensuring the physical and economic health and well-being of the communities they serve.',
  HEALTH_AND_WELLNESS:
    "Community amenities and offerings directly impact residents' physical and emotional health and general well being overall.",
  INCLUSIVITY_AND_ENGAGEMENT:
    'A community in which all members feel welcome and to which they feel that they belong provides a foundation for building trust and engagement to tackle local problems and improve quality of life for all.',
  EDUCATION_ARTS_CULTURE:
    'Participation in the arts, educational opportunities, and cultural activities are linked to increased civic engagement, greater social capital, and enhanced community enjoyment.',
  COMMUNITY_CHARACTERISTICS: 'Community Characteristics description.',
  GOVERNANCE:
    'Strong local governments produce results that meet the needs of residents while making the best use of available resources. They are responsive to the present and future needs of the community as a whole.',
  COMMUNITY_LIVABILITY: 'Community Livability description.',
  NATURAL_ENVIRONMENT:
    'The natural environment and environmental health play a vital role in the well-being of residents. Providing natural spaces and ensuring a healthy relationship between people and their environment has a direct and profound effect on quality of life.',
  PARKS_AND_RECREATION:
    'A strong system of parks and recreation creates desirable communities with opportunities to stay active and engaged.',
};
