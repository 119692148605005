import { DomainChartContainer } from 'client/shared/containers/domain-chart-container';
import { getFilteredBenchmarkFooter } from 'client/shared/core/performance-data';
import { domainDescriptions, domainLabels } from 'client/shared/core/track/labels';
import {
  ActivationState,
  BenchmarkFilter,
  ChartType,
  NationalDataFips,
  StatisticType,
  TrackAreaMode,
  TrackDatumWithDistributions,
  TrackVariableWithDistributions,
  VariableDisplayType,
  VisualizationType,
  getMostRecentDatumCurrentPub,
  wrap,
} from 'core';
import * as React from 'react';
import { ContentUnavailableType } from '../content-unavailable';
import { DashboardCard } from '../dashboard-card';
import { DomainCardContent } from '../domain-card';
import { CommunityStatisticsCard } from '../domain-community-statistics-card';
import { ResidentSentiment } from '../resident-sentiment';
import { VisualizationUnavailable } from './not-found';
import { GroupIndexScoresContainer } from 'client/shared/containers/group-index-scores-container';
import { GroupIndicatorCard } from '../group-indicator-card';

export enum EmbedDataPointContext {
  CONTENT_POST = 'CONTENT_POST',
  VISUALIZATION = 'VISUALIZATION',
}
export interface VisualizationData {
  readonly recordedAt: Date;
  readonly state: ActivationState;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly visualizationType: VisualizationType;
  readonly trackVariable: TrackVariableWithDistributions;
  readonly baseFips: string;
  readonly id: string;
  readonly comparisonGroupId?: string;
}
interface VisualizationPickerProps {
  readonly visualizationData: VisualizationData | null;
  readonly expanded: boolean;
  readonly postEmbed?: boolean;
  readonly toggleExpanded: () => void;
  readonly topLevelEmbed?: boolean;
  readonly label?: string;
  readonly showInactive?: boolean;
}

export const VisualizationPicker: React.FC<VisualizationPickerProps> = (props) => {
  const [chartType, setChartType] = React.useState(
    props.visualizationData?.visualizationType &&
      props.visualizationData.visualizationType === VisualizationType.LINE
      ? ChartType.LINE
      : ChartType.HISTOGRAM
  );
  if (!props.visualizationData) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.NOT_FOUND}
      />
    );
  }
  const {
    state,
    benchmarkFilter,
    visualizationType,
    trackVariable: performanceData,
  } = props.visualizationData;

  const { areasData } = performanceData;

  // need to grab either the basefips from the array if there are multiple, or if theres only one we can assume its the basefips
  const currentFips = performanceData.areasData.find(
    (d) => d.fipsArea.id === props.visualizationData?.baseFips
  )?.fipsArea;

  if (!currentFips) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.NOT_FOUND}
      />
    );
  }

  const areaMode =
    currentFips.id === NationalDataFips
      ? TrackAreaMode.NATIONAL
      : TrackAreaMode.LOCAL;

  const footer =
    areaMode === TrackAreaMode.LOCAL
      ? getFilteredBenchmarkFooter(benchmarkFilter)
      : null;

  if (state === ActivationState.INACTIVE && !props.showInactive) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.UNAVAILABLE}
      />
    );
  }

  const embedContext = wrap(() => {
    if (props.topLevelEmbed) {
      return EmbedDataPointContext.VISUALIZATION;
    } else if (props.postEmbed) {
      return EmbedDataPointContext.CONTENT_POST;
    } else return;
  });
  const latestPerformanceData = getMostRecentDatumCurrentPub<
    TrackDatumWithDistributions,
    TrackVariableWithDistributions
  >(currentFips, performanceData);

  if (areasData.length > 1 && props.visualizationData.comparisonGroupId) {
    return props.visualizationData.trackVariable.statisticType ===
      StatisticType.INDEX ? (
      <DashboardCard
        areaMode={areaMode}
        benchmarkFilter={benchmarkFilter}
        chartTypeToSave={chartType}
        currentFips={currentFips?.id}
        description={domainDescriptions[performanceData.domains[0].domain]}
        recentValue={latestPerformanceData}
        title={domainLabels[performanceData.domains[0].domain]}
        variable={performanceData}
      >
        <GroupIndexScoresContainer
          benchmarkFilter={benchmarkFilter}
          chartType={chartType}
          comparisonGroupId={props.visualizationData.comparisonGroupId}
          currentFips={currentFips}
          displayType={VariableDisplayType.DEFAULT}
          domain={performanceData.domains[0].domain}
          embedContext={embedContext}
          setChartType={setChartType}
          trackVariables={[performanceData]}
        />
      </DashboardCard>
    ) : (
      <GroupIndicatorCard
        benchmarkFilter={benchmarkFilter}
        comparisonGroupId={props.visualizationData.comparisonGroupId}
        currentFips={currentFips?.id}
        displayType={VariableDisplayType.DEFAULT}
        domain={performanceData.domains[0].domain}
        embedContext={embedContext}
        expanded={props.expanded}
        indicator={performanceData}
        showFooter
        toggleExpanded={props.toggleExpanded}
      />
    );
  }
  switch (visualizationType) {
    case VisualizationType.LINE: {
      return performanceData.statisticType === StatisticType.INDICATOR ? (
        <CommunityStatisticsCard
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips}
          domain={performanceData.domains[0].domain}
          embedContext={embedContext}
          expanded={props.expanded}
          showFooter={areaMode === TrackAreaMode.LOCAL}
          toggleExpanded={props.toggleExpanded}
          variable={performanceData}
        />
      ) : (
        <DashboardCard
          areaMode={areaMode}
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips?.id}
          description={domainDescriptions[performanceData.domains[0].domain]}
          embedContext={embedContext}
          title={domainLabels[performanceData.domains[0].domain]}
        >
          <DomainCardContent
            domain={performanceData.domains[0].domain}
            indexScoreData={latestPerformanceData}
            variable={performanceData}
          />
          {footer && (
            <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
          )}
          <DomainChartContainer
            areaMode={areaMode}
            benchmarkFilter={benchmarkFilter}
            currentFips={currentFips}
            defaultChart={chartType}
            indexScore={performanceData}
            setChart={setChartType}
          />
        </DashboardCard>
      );
    }
    case VisualizationType.HISTOGRAM: {
      return (
        <DashboardCard
          areaMode={areaMode}
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips?.id}
          description={domainDescriptions[performanceData.domains[0].domain]}
          embedContext={embedContext}
          title={domainLabels[performanceData.domains[0].domain]}
        >
          <DomainCardContent
            domain={performanceData.domains[0].domain}
            indexScoreData={latestPerformanceData}
            variable={performanceData}
          />
          {footer && (
            <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
          )}
          <DomainChartContainer
            areaMode={
              currentFips.id === NationalDataFips
                ? TrackAreaMode.NATIONAL
                : TrackAreaMode.LOCAL
            }
            benchmarkFilter={benchmarkFilter}
            currentFips={currentFips}
            defaultChart={chartType}
            indexScore={performanceData}
            setChart={setChartType}
          />
        </DashboardCard>
      );
    }
    case VisualizationType.PROGRESS:
    default:
      return (
        <ResidentSentiment
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips}
          domain={performanceData.domains[0].domain}
          embedContext={embedContext}
          hideSaveAsImage
          hideTabBar
          sentimentValues={[performanceData]}
          showFooter
          showOnlyTopSentimentValues={false}
        />
      );
  }
};
