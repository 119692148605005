import React from 'react';
import moment from 'moment';
import {
  BtnLink,
  ButtonTypes,
  ExpandMode,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { BenchmarkIndicator } from 'client/shared/components/benchmark-indicator';
import {
  CtaLinkProps,
  CtaLinkType,
  TrackDatum,
  TrackVariableMetadata,
  YEAR_FORMAT,
} from 'core';
import { SimpleProgressBar } from 'client/shared/components/simple-progress-bar';
import { Tooltip } from 'client/shared/components/tooltip';
import './styles.scss';
import { BenchmarkFilter } from 'client/shared/graphql-client/graphql-operations.g';
import {
  analyticsBenchmarkToClient,
  formatIndicatorValue,
  getFilteredBenchmarkFooter,
  getFilteredBenchmarkValue,
} from 'client/shared/core/performance-data';

const baseClass = 'pn-dashboard-primary-indicator';

export interface Props {
  readonly className?: string;
  readonly ctaProps?: CtaLinkProps;
  readonly indicatorData?: TrackDatum;
  readonly variable?: TrackVariableMetadata;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly showFooter?: boolean;
  readonly indicatorOptionsContent?: React.ReactNode;
}

export const primaryIndicatorCopy = {
  positive: 'Positive',
  unavailableData: 'Data unavailable',
};

export const PrimaryIndicator: React.FC<Props> = (p) => {
  const { indicatorData, variable } = p;
  const footer = getFilteredBenchmarkFooter(p.benchmarkFilter);
  const renderUnavailable = () => {
    return (
      <>
        <div
          className={`${baseClass}-unavailable-label font-size-lg text-gray-40 font-weight-bold`}
        >
          {primaryIndicatorCopy.unavailableData}
        </div>
        {p.ctaProps && (
          <BtnLink
            className="mt-2"
            target={p.ctaProps.type === CtaLinkType.EXTERNAL ? '_blank' : ''}
            to={p.ctaProps.link}
            type={ButtonTypes.SECONDARY}
          >
            {p.ctaProps.text}
          </BtnLink>
        )}
      </>
    );
  };

  if (!variable) {
    return (
      <div className={`${baseClass} bg-gray-6 ${p.className ?? ''}`}>
        {renderUnavailable()}
      </div>
    );
  }

  const getTooltipContent = (indData: TrackDatum) => {
    return indData.value
      ? `${moment.utc(indData.recordedAt).format(YEAR_FORMAT)} ${variable.source}.`
      : '';
  };

  const value = indicatorData?.value ?? 0;
  const benchmarkValue = indicatorData
    ? getFilteredBenchmarkValue(indicatorData, p.benchmarkFilter)
    : undefined;
  return (
    <div className={`${baseClass} bg-gray-6 ${p.className ?? ''}`}>
      <div
        className={`${baseClass}-header d-flex align-items-center justify-content-between`}
      >
        <div className="d-flex flex-row flex-grow-1">
          <div className="text-gray-60 flex-grow-1">{variable.label}</div>
          {p.indicatorOptionsContent}
          {indicatorData && indicatorData.value !== null && (
            <Tooltip
              content={['Data from your', getTooltipContent(indicatorData)].join(
                ' '
              )}
              htmlContent={
                <div className={`${baseClass}-tooltip-container`}>
                  Data from your{' '}
                  <span className="font-weight-bold">
                    {getTooltipContent(indicatorData)}
                  </span>
                </div>
              }
              id={`${variable.name}-tooltip`}
              place="top"
            >
              <MaterialIcon
                className={`${baseClass}-icon text-jungle font-size-base cursor-pointer no-show-in-image`}
                icon={MaterialIconName.INFO_OUTLINE}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <SimpleProgressBar className="mb-2" value={value} />
      {!indicatorData || indicatorData.value === null ? (
        renderUnavailable()
      ) : (
        <>
          <div
            className={`${baseClass}-percentage-label mb-2 text-gray-60 font-weight-bold font-size-lg`}
          >
            {formatIndicatorValue(value, variable.valueType, 0)}{' '}
            {primaryIndicatorCopy.positive}
          </div>
          {benchmarkValue && (
            <>
              <BenchmarkIndicator
                benchmarkValue={analyticsBenchmarkToClient(benchmarkValue)}
                expandMode={ExpandMode.ALWAYS_EXPANDED}
              />
              {p.showFooter && (
                <div className="font-size-sm py-2 no-show-in-image">{footer}</div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
