import React from 'react';
import {
  ComparisonGroupResultTableColumn,
  TransformedData,
} from './comparison-group-result-table-column';
import _ from 'lodash';
import { TrackAreaData, TrackDatum, TrackVariable, VariableDirection } from 'core';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group-wrapper';
import { RespondentLayout, useLayout } from 'client/shared/hooks';

const baseClass = 'pn-comparison-group-result-table';
export interface FipsAreaRow {
  readonly id: string;
  readonly name: string;
  readonly color: string;
  readonly isHidden: boolean;
  readonly isHoverRow: boolean;
}
interface Props {
  readonly variable: Pick<TrackVariable, 'id' | 'name' | 'valueType' | 'direction'>;
  readonly data: readonly DomainIndicatorsFipsAreaRow[];
  readonly isActionable: boolean;
  readonly onRowClick: (rowId: string) => any;
  readonly setHoverLine: (rowId: string | null) => void;
  readonly entriesPerPage: number;
  readonly pageOffset: number;
  readonly isEmbedded?: boolean;
  readonly hoverLine: string | null;
  readonly fipsAreaVisibility: Record<string, boolean>;
}

export const ComparisonGroupResultTable: React.FC<Props> = (props) => {
  const {
    variable,
    data,
    entriesPerPage,
    fipsAreaVisibility,
    hoverLine,
    isActionable,
    onRowClick,
    setHoverLine,
    pageOffset,
    isEmbedded,
  } = props;
  const { isMobile } = useLayout({
    mobileBreakingPoint: RespondentLayout.ContentWidth + 15,
  });

  const transFormedDataWithItemNumbers = React.useMemo(() => {
    const transformedData = _.compact(_.sortBy(transformData(data), (v) => v.name));
    const sortByVariableDirection = transformedData.sort((a, b) => {
      switch (variable.direction) {
        case VariableDirection.NEG: {
          if (!a.recentDatum?.value && b.recentDatum?.value) {
            return 1;
          }
          if (a.recentDatum?.value && !b.recentDatum?.value) {
            return -1;
          }
          return (a.recentDatum?.value ?? 0) - (b.recentDatum?.value ?? 0);
        }
        case VariableDirection.POS:
        default:
          return (b.recentDatum?.value ?? 0) - (a.recentDatum?.value ?? 0);
      }
    });
    let place = 0;

    return sortByVariableDirection.map((value, idx) => {
      return {
        ...value,
        itemNumber:
          value.recentDatum?.value ===
          sortByVariableDirection[idx - 1]?.recentDatum?.value
            ? place
            : ++place,
      };
    });
  }, [data, variable.direction]);

  const ComparisonGroupResultTableColumnMemo = React.useMemo(() => {
    if (!isMobile && !isEmbedded) {
      return (
        <div className={`${baseClass}-double  w-100  justify-content-center`}>
          <div className="row">
            <div className={'col col-6'}>
              <ComparisonGroupResultTableColumn
                data={transFormedDataWithItemNumbers.slice(
                  pageOffset * entriesPerPage * 2,
                  pageOffset * entriesPerPage * 2 + entriesPerPage
                )}
                entriesPerPage={entriesPerPage}
                fipsAreaVisibility={fipsAreaVisibility}
                hoverLine={hoverLine}
                isActionable={isActionable}
                onRowClick={onRowClick}
                setHoverLine={setHoverLine}
              />
            </div>
            <div className={'col col-6'}>
              <ComparisonGroupResultTableColumn
                data={transFormedDataWithItemNumbers.slice(
                  pageOffset * 2 * entriesPerPage + entriesPerPage,
                  pageOffset * 2 * entriesPerPage + 2 * entriesPerPage
                )}
                entriesPerPage={entriesPerPage}
                fipsAreaVisibility={fipsAreaVisibility}
                hoverLine={hoverLine}
                isActionable={isActionable}
                onRowClick={onRowClick}
                setHoverLine={setHoverLine}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className={`${baseClass}-single w-100  justify-content-center`}>
          <ComparisonGroupResultTableColumn
            data={transFormedDataWithItemNumbers.slice(
              pageOffset * entriesPerPage * 2,
              entriesPerPage * 2 * (pageOffset + 1)
            )}
            entriesPerPage={entriesPerPage * 2}
            fipsAreaVisibility={fipsAreaVisibility}
            hoverLine={hoverLine}
            isActionable={isActionable}
            onRowClick={onRowClick}
            setHoverLine={setHoverLine}
          />
        </div>
      </div>
    );
  }, [
    isMobile,
    transFormedDataWithItemNumbers,
    pageOffset,
    entriesPerPage,
    isActionable,
    onRowClick,
    isEmbedded,
    setHoverLine,
    fipsAreaVisibility,
    hoverLine,
  ]);
  return ComparisonGroupResultTableColumnMemo;
};

ComparisonGroupResultTable.displayName = 'ComparisonGroupResultTable';

function getRecentDatum(trackAreaDatum: TrackAreaData): TrackDatum | null {
  const performanceDataSortedByRecordedAt = _.sortBy(
    trackAreaDatum.performanceData,
    ({ recordedAt }) => recordedAt
  );
  if (performanceDataSortedByRecordedAt.length > 0) {
    const recentDatum =
      performanceDataSortedByRecordedAt[
        performanceDataSortedByRecordedAt.length - 1
      ];
    return recentDatum;
  }
  return null;
}

function transformData(
  data: readonly DomainIndicatorsFipsAreaRow[]
): readonly Omit<TransformedData, 'itemNumber'>[] {
  return data.map((row) => {
    const recentDatum = getRecentDatum(row.trackAreaData);
    return {
      ...row,
      recentDatum,
    };
  });
}
