import { useGetNivoColors } from 'client/shared/hooks/use-get-nivo-colors';
import * as React from 'react';
import './styles.scss';
import {
  AnalyticsValueType,
  DateLevel,
  FipsAreaWithShortName,
  TrackAreaData,
  TrackVariable,
} from 'core';
import _ from 'lodash';
import { ComparisonGroupLineChart } from '../domain-indicators-group-line-chart';
import { ComparisonGroupResultTable } from '../comparison-group-result-table';

interface Props {
  readonly label: string | null;
  readonly variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel' | 'statisticType'
  >;
  readonly fipsAreas: readonly FipsAreaWithShortName[];
  readonly trackAreaData: readonly TrackAreaData[];
  readonly showLineChart: boolean;
  readonly isEmbedded?: boolean;
}

export interface DomainIndicatorsFipsAreaRow {
  readonly id: string; //root Fips
  readonly dataAreaFips: string;
  readonly name: string;
  readonly color: string;
  readonly trackAreaData: TrackAreaData;
  readonly variableName: string;
  readonly variableValueType: AnalyticsValueType;
  readonly variableDateLevel: DateLevel;
}
const baseClass = 'pn-domain-indicators-group-wrapper';

export const DomainIndicatorsGroupWrapper: React.FC<Props> = (props) => {
  const { getColors } = useGetNivoColors();
  const dataAreas = _.uniqBy(
    props.trackAreaData.flatMap((ad) => ad.performanceData.map((pd) => pd.fipsArea)),
    (fa) => fa.id
  );

  const colors = getColors(dataAreas.length);
  const areaColors = dataAreas.reduce<Record<string, string>>((prev, curr, idx) => {
    return { ...prev, [curr.id]: colors[idx] };
  }, {});
  const entriesPerPage = _.ceil(props.trackAreaData.length / 2);

  const [fipsAreaVisibility, setFipsAreaVisibility] = React.useState<
    Record<string, boolean> //Key is the unique fips of the data points loaded for each line
  >(
    dataAreas.reduce<Record<string, boolean>>((prev, curr) => {
      return { ...prev, [curr.id]: true };
    }, {})
  );

  const [hoverLine, setHoverLine] = React.useState<string | null>(null); //value is the color of the hovered line or row(s)

  const toggleShowingFipsArea = (rowId: string) => {
    setFipsAreaVisibility((prevFipsAreaVisibility) => {
      return {
        ...prevFipsAreaVisibility,
        [rowId]: !prevFipsAreaVisibility[rowId],
      };
    });
  };

  const data = React.useMemo(
    () =>
      toDomainIndicatorsFipsAreaRow(
        props.variable,
        props.fipsAreas,
        props.trackAreaData,
        areaColors
      ),
    [areaColors, props.fipsAreas, props.trackAreaData, props.variable]
  );
  const lineChartData = React.useMemo(
    () => _.uniqBy(data, (row) => row.color),
    [data]
  );

  return (
    <div className={`${baseClass}`}>
      {props.label && <div className={`${baseClass}-label`}>{props.label}</div>}

      {props.showLineChart && (
        <ComparisonGroupLineChart
          data={lineChartData}
          fipsAreaVisibility={fipsAreaVisibility}
          hoverLine={hoverLine}
          setHoverLine={setHoverLine}
          variable={props.variable}
        />
      )}

      <ComparisonGroupResultTable
        data={data}
        entriesPerPage={entriesPerPage}
        fipsAreaVisibility={fipsAreaVisibility}
        hoverLine={hoverLine}
        isActionable={props.showLineChart}
        isEmbedded={props.isEmbedded}
        onRowClick={toggleShowingFipsArea}
        pageOffset={0}
        setHoverLine={setHoverLine}
        variable={props.variable}
      />
    </div>
  );
};

export function toDomainIndicatorsFipsAreaRow(
  variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel'
  >,
  fipsAreas: readonly FipsAreaWithShortName[],
  trackAreaData: readonly TrackAreaData[],
  colors: Record<string, string>
): readonly DomainIndicatorsFipsAreaRow[] {
  const trackAreaDataByFipsAreaId = _.keyBy(trackAreaData, (d) => d.fipsArea.id);
  return fipsAreas.map((fipsArea) => {
    const trackAreaDataForFips = trackAreaDataByFipsAreaId[fipsArea.id];
    const dataArea = trackAreaDataForFips
      ? trackAreaDataForFips.performanceData[0]?.fipsArea
      : null;
    const dataAreaFips = dataArea?.id ?? fipsArea.id;
    return {
      id: fipsArea.id,
      dataAreaFips: dataAreaFips,
      name: fipsArea.shortName ?? fipsArea.name,
      color: colors[dataAreaFips],
      trackAreaData: trackAreaDataForFips ?? [],
      variableName: variable.name,
      variableValueType: variable.valueType,
      variableDateLevel: variable.dateLevel,
    };
  });
}
