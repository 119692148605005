import { IconButton } from '@material-ui/core';
import { useGenerateImage } from 'client/shared/hooks';
import { MaterialIcon, MaterialIconName } from 'client/shared/components/base';
import { Tooltip } from 'client/shared/components/tooltip';
import * as React from 'react';
import { useId } from 'react-use-id-hook';

interface Props {
  readonly imageRef: React.RefObject<HTMLElement>;
  readonly name: string;
  readonly buttonClassName: string; //required because used for heap tracking
  readonly afterImageAction?: () => void;
  readonly parentClassType?: '-wide';
  readonly isOnHover?: boolean;
  readonly beforeImageAction?: () => void;
  readonly customDownloadImageAction?: () => Promise<void>;
}

const COPY = {
  tooltip: 'Download as Image',
};

export const DownloadImageButton: React.FC<Props> = (p) => {
  const generateImage = useGenerateImage(
    p.imageRef,
    p.name,
    p.afterImageAction,
    p.parentClassType,
    p.beforeImageAction
  );
  React.useEffect(() => {
    if (!!p.isOnHover) {
      p.imageRef.current?.classList.add('hide-save-image-button');
    }
  }, [p.imageRef, p.isOnHover]);
  return (
    <Tooltip
      className="font-size-sm font-weight-normal no-show-in-image"
      content={COPY.tooltip}
      id={useId()}
    >
      <IconButton
        className={`no-show-in-image p-1 save-image ${p.buttonClassName} u-no-print`}
        onClick={p.customDownloadImageAction || generateImage}
      >
        <MaterialIcon
          ariaLabel="Download as Image"
          icon={MaterialIconName.DOWNLOAD}
          iconType="material-icons-outlined"
        />
      </IconButton>
    </Tooltip>
  );
};
