import * as React from 'react';
import './styles.scss';

const baseClass = 'pn-null-overlay';
export const NullOverlay: React.FC<{
  readonly enabled: boolean;
  readonly className?: string;
}> = (p) => {
  return (
    <div className={`${baseClass} h-100 ${p.className ?? ''}`}>
      {p.enabled && (
        <div
          className={`${baseClass}-no-data text-gray-40 font-weight-bold font-size-sm text-center`}
        >
          {DataUnavailableMessage}
        </div>
      )}
      <div className={`${p.enabled ? 'is-enabled' : 'is-disabled'} h-100`}>
        {p.children}
      </div>
    </div>
  );
};

export const DataUnavailableMessage = 'Data currently unavailable';
