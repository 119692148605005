import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { DistributionBin } from 'core';
import {
  COLORS_LIBERTY_HEX,
  grayPalette,
  indexScoreColors,
} from 'client/shared/core/colors';
import _ from 'lodash';
const countFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});
const findAppropriateBinForDataPoint = (
  dataPoint: number,
  bins: readonly DistributionBin[]
) => {
  const span =
    bins.length > 1 ? (bins[1].percentile - bins[0].percentile) / 2 : null;

  if (!span) {
    return bins[0];
  }
  for (let i = 0; i < bins.length; i++) {
    if (dataPoint <= bins[i].percentile) {
      return bins[i].percentile - dataPoint < span
        ? bins[i]
        : bins[i - 1] ?? bins[0];
    }
  }
  return bins[bins.length - 1]; //never found a percentile it was less than, its in the 100% range.
};

const findAvgBin = (bins: readonly DistributionBin[]) => {
  const mean = _.sumBy(bins, (currBin) => currBin.percentValue * currBin.percentile);
  const bin = findAppropriateBinForDataPoint(mean, bins);
  return { bin, mean };
};
export const TOOLTIP_SIZE = {
  WIDTH: 120,
  HEIGHT: 24,
  GAP: 8,
};

export interface HistogramProps {
  readonly bins: readonly DistributionBin[];
  readonly indexScore?: number;
  readonly parentWidth?: number;
  readonly maintainAspectRatio?: boolean;
}

export const Histogram: React.FC<HistogramProps> = (props) => {
  const { bins, indexScore, parentWidth } = props;
  findAvgBin(bins);
  const fontColor = '#656B65';
  const chartRef = useRef<SVGSVGElement | null>(null);
  const highestValue = Math.max(...bins.map((bin) => bin.percentValue));
  const lowestValue = Math.min(...bins.map((bin) => bin.percentValue));
  // set the dimensions and margins of the graph
  const margin = { top: 10, right: 30, bottom: 30, left: 0 };
  const tooltipSpace = TOOLTIP_SIZE.HEIGHT + TOOLTIP_SIZE.GAP;
  const width = parentWidth ?? 320 + margin.left + margin.right; // 328
  const height = props.maintainAspectRatio ? width / 8 : 40;
  const xAxis = d3
    .scaleBand()
    .range([0, width])
    .domain(bins.map((d) => d.percentile.toString()))
    .paddingInner(0.1);

  const xAxisAvgScale = d3.scaleLinear().range([0, width]).domain([0, 100]);
  const spaceBetweenBars = (width * xAxis.paddingInner()) / 19;

  const drawColorLine = (
    svg: d3.Selection<SVGGElement, unknown, null, undefined>,
    color: string,
    xTransform: number,
    index: number
  ) => {
    svg
      .append('line')
      .style('stroke', color)
      .style('stroke-width', 2)
      .attr(
        'transform',
        `translate(${index * xTransform}, ${transformsCalc.coloredLines.y})`
      )
      .attr('x1', 0)
      .attr('x2', 4 * xAxis.bandwidth() + 3 * spaceBetweenBars);
  };
  const drawTooltip = (svg: d3.Selection<SVGGElement, unknown, null, undefined>) => {
    // overall tooltip
    const tooltip = svg
      .append('g')
      .classed('pn-d3-tooltip', true)
      .style('display', 'none');
    // body of tooltip
    tooltip
      .append('rect')
      .classed('pn-d3-tooltip-body', true)
      .attr('width', TOOLTIP_SIZE.WIDTH)
      .attr('height', TOOLTIP_SIZE.HEIGHT)
      .attr('fill', 'black')
      .attr('opacity', '0.75');

    //Tooltip text
    tooltip
      .append('text')
      .classed('pn-d3-tooltip-body-text', true)
      .attr('x', TOOLTIP_SIZE.WIDTH / 2)
      .attr('y', TOOLTIP_SIZE.HEIGHT / 2)
      .style('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
      .attr('fill', 'white')
      .attr('dominant-baseline', 'central');
  };
  const handleTooltipEdgeXtransform = (xTransform: number) => {
    if (xTransform < 0) {
      return 0;
    }
    if (xTransform + TOOLTIP_SIZE.WIDTH > width) {
      return width - TOOLTIP_SIZE.WIDTH;
    }
    return xTransform;
  };

  const minimumBarHeight = 5;
  // Add Y axis
  const yAxis = d3
    .scaleLinear()
    .domain([lowestValue, highestValue]) // input range - % values
    .range([height - minimumBarHeight, 0]); //output range - pixel values

  const indexScoreBin = indexScore
    ? findAppropriateBinForDataPoint(indexScore, bins)
    : null;
  const valueToUseForAvg = findAvgBin(bins);
  // const diff = valueToUseForAvg.bin.percentile - valueToUseForAvg.mean;
  // const additionalBandwidth = diff / xAxis.range()[1];
  const transformsCalc = {
    xCenter: (width * 50) / 100,
    avgLine: {
      x: xAxisAvgScale(valueToUseForAvg.mean),
      y: yAxis(valueToUseForAvg.bin.percentValue),
    },
    coloredLines: {
      x: width / 5,
      y: height + 3,
    },
  };

  const avgLineHeight = height - yAxis(valueToUseForAvg.bin.percentValue);

  const drawChartElements = (
    selection: d3.Selection<SVGGElement, unknown, null, undefined>,
    x: d3.ScaleBand<string>,
    y: d3.ScaleLinear<number, number>
  ) => {
    selection
      .selectAll('g')
      .data([...bins])
      .enter()
      .append('rect')
      .classed('bar', true)
      .attr('id', (d) => d.id)
      .attr('x', (d) => x(`${d.percentile}`) ?? null)
      .attr('y', (d) => y(d.percentValue))
      .attr('width', x.bandwidth())
      .attr('height', (d) => {
        return height - y(d.percentValue);
      })
      .attr('fill', (d) => {
        return indexScoreBin?.percentile === d.percentile
          ? COLORS_LIBERTY_HEX
          : grayPalette.Gray4;
      })
      .on('mouseover', function (d) {
        if (!_.isNumber(d.count)) {
          return;
        }
        d3.select(this).style('stroke', 'black');
        const xTransform =
          (x(`${d.percentile}`) ?? 0) + x.bandwidth() / 2 - TOOLTIP_SIZE.WIDTH / 2;
        selection
          .select('g.pn-d3-tooltip')
          .style('display', 'inline')
          .attr(
            'transform',
            `translate(${handleTooltipEdgeXtransform(xTransform)}, ${y(d.percentValue) - 8 - TOOLTIP_SIZE.HEIGHT})`
          );
        const count = d.count ?? 0;
        selection
          .select('text.pn-d3-tooltip-body-text')
          .text(
            `${countFormatter.format(count)} Communit${count === 1 ? 'y' : 'ies'}`
          );
      })
      .on('mouseleave', function () {
        selection.select('g.pn-d3-tooltip').style('display', 'none');
        d3.select(this).style('stroke', 'none');
      });

    // redraw colored lines
    Object.values(indexScoreColors).forEach((color, idx) =>
      drawColorLine(selection, color.hex, transformsCalc.coloredLines.x + 0.4, idx)
    );
    // redraw axes text
    // Draw average Text
    selection
      .append('text')
      .text('Avg')
      .attr('font-size', '12px')
      .attr('fill', fontColor)
      .attr('x', function () {
        const textWidth: number = this.getComputedTextLength();
        return (transformsCalc.avgLine.x ?? transformsCalc.xCenter) - textWidth / 2;
      })
      .attr('y', -0.5 * margin.top);

    // redraw avg line
    selection
      .append('line')
      .style('stroke', 'black')
      .style('stroke-width', 1)
      .style('stroke-dasharray', '8,3')
      .attr(
        'transform',
        `translate(${transformsCalc.avgLine.x}, ${transformsCalc.avgLine.y})`
      )
      .attr('id', 'avg-line')
      .attr('x', 0)
      .attr('y1', 0)
      .attr('y2', avgLineHeight);
    // .attr('height', height - y(valueToUseForAvg));
    // redraw triangle
    selection
      .append('path')
      .attr('d', d3.symbol().type(d3.symbolTriangle).size(50))
      .attr('fill', 'black')
      .attr('transform', `translate(${transformsCalc.avgLine.x}, ${height + 2})`)
      .attr('stroke', 'white')
      .attr('stroke-width', 1)
      .attr('id', 'avg-triangle');

    // Add Axis Text
    selection // '0' text
      .append('text')
      .text('0')
      .attr('fill', fontColor)
      .attr('font-size', '12px')
      .attr('x', 0)
      .attr('y', height + 15);

    selection // '100' text
      .append('text')
      .text('100')
      .attr('font-size', '12px')
      .attr('fill', fontColor)
      .attr('x', function () {
        const textWidth: number = this.getComputedTextLength();
        return width - textWidth;
      })
      .attr('y', height + 15);
    drawTooltip(selection);
  };
  const drawChart = (x: d3.ScaleBand<string>, y: d3.ScaleLinear<number, number>) => {
    // append the svg object to the body of the page
    const svg = d3
      .select(chartRef.current)
      .attr('width', width)
      // .attr('width', '100%')
      .attr('height', height + tooltipSpace + margin.top + margin.bottom)
      .append('g')
      .attr('id', 'mainG')
      .attr('width', width)
      // .attr('width', '100%')
      .attr('height', height + tooltipSpace + margin.top + margin.bottom)
      .attr(
        'transform',
        `translate(${margin.left},${margin.top * 1.5 + tooltipSpace})`
      );

    drawChartElements(svg, x, y);
  };

  useEffect(() => {
    drawChart(xAxis, yAxis);
  }, [parentWidth]);

  useEffect(() => {
    const redrawBars = (
      x: d3.ScaleBand<string>,
      y: d3.ScaleLinear<number, number>
    ) => {
      const svg = d3
        .select(chartRef.current)
        .attr('transform', `translate(${margin.left},${margin.top * 1.5})`);

      svg.selectAll('g').remove();
      svg
        .append('g')
        .attr('id', 'mainG')
        .attr('width', width)
        .attr('height', height + margin.top + margin.bottom + tooltipSpace)
        .attr(
          'transform',
          `translate(${margin.left},${margin.top * 1.5 + tooltipSpace})`
        );
      svg.selectAll('.bar').remove();
      svg.selectAll('#avg-line').remove();
      svg.selectAll('#avg-triangle').remove();

      const g: d3.Selection<SVGGElement, unknown, null, undefined> = svg.select('g');

      drawChartElements(g, x, y);
    };

    redrawBars(xAxis, yAxis);
  }, [indexScore, chartRef, indexScoreBin, bins, parentWidth]);

  return <svg id="histogram" ref={chartRef} />;
};
