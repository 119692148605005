import * as React from 'react';
import { DataUnavailable } from 'client/shared/components/data-unavailable';
import { DifferenceIndicator } from 'client/shared/components/difference-indicator';
import { Histogram } from 'client/shared/components/data-viz-d3/histogram';
import { BenchmarkFilter } from 'client/shared/graphql-client/graphql-operations.g';
import './styles.scss';
import ReactResizeDetector from 'react-resize-detector';
import {
  DateLevel,
  DistributionCoreData,
  TrackDatum,
  TrackVariableMetadata,
} from 'core';
import {
  getBackgroundColorClass,
  getBorderColorClass,
} from 'client/shared/core/performance-data';
import { LoaderBars } from '../loader-bars';

export const UnavailableCard: React.FC<{
  readonly className?: string;
  readonly title?: string;
  readonly hideBorder?: boolean;
}> = (p) => {
  return (
    <div
      className={`${baseClass} ${
        !p.hideBorder ? `${baseClass}-border` : ''
      } d-flex flex-column ${p.className ?? ''}`}
    >
      <div
        className={`${baseClass}-border-line d-flex justify-content-between align-items-center border-bottom border-radius-lg ${getBorderColorClass(
          null
        )}`}
      >
        <span
          className={`${baseClass}-label font-size-base text-gray-60 font-weight-bold`}
        >
          {p.title}
        </span>
      </div>

      <div className="d-flex justify-content-center">
        <DataUnavailable
          className={`font-weight-bold mx-4 my-5 py-1 ${baseClass}-unavailable`}
          hideTooltip
          title={indexScoreChartCopy.unavailable.basic}
          titleClass="font-size-sm"
        />
      </div>
    </div>
  );
};

interface Props {
  readonly className?: string;
  readonly indexScoreData?: Omit<TrackDatum, 'benchmarkValue'>;
  readonly variable?: TrackVariableMetadata;
  readonly title?: string;
  readonly distributions: readonly DistributionCoreData[];
  readonly benchmarkFilter?: BenchmarkFilter;
  readonly hideBorder?: boolean;
  readonly maintainAspectRatio?: boolean;
  readonly comparisonGroupId: string | null;
}

const baseClass = 'pn-index-score-chart';

const indexScoreChartCopy = {
  unavailable: {
    basic: 'Data currently unavailable',
    distributions: 'Distribution data currently unavailable',
  },
};

export const IndexScoreChart: React.FC<Props> = (p) => {
  const {
    className,
    title,
    indexScoreData,
    distributions,
    benchmarkFilter,
    variable,
  } = p;
  const HistogramMemo = React.memo(Histogram);

  const selectedBins = React.useMemo(() => {
    const selectedDistribution = distributions.find((d) =>
      p.comparisonGroupId
        ? d.comparisonGroupId === p.comparisonGroupId
        : d.filter === benchmarkFilter && d.comparisonGroupId === null
    );

    return selectedDistribution?.bins ?? [];
  }, [benchmarkFilter, distributions, p.comparisonGroupId]);

  if (!selectedBins.length || !variable) {
    return <UnavailableCard className={className} title={title} />;
  }
  const histogramDataAvailable = distributions.length > 0;

  const fixedIndexScore = indexScoreData?.value.toFixed();

  return (
    <ReactResizeDetector handleWidth>
      {({ width }: { readonly width: number }) => {
        if (!width) {
          return <LoaderBars />;
        }
        return (
          <div
            className={`${baseClass} ${
              !p.hideBorder ? `${baseClass}-border` : ''
            } d-flex flex-column ${className ?? ''}`}
          >
            {title && (
              <div
                className={`${baseClass}-border-line d-flex justify-content-between align-items-center border-bottom border-radius-lg ${
                  fixedIndexScore
                    ? getBorderColorClass(parseInt(fixedIndexScore))
                    : 'border-gray-30'
                }`}
              >
                <span
                  className={`${baseClass}-label font-size-base text-gray-60 font-weight-bold`}
                >
                  {title}
                </span>
                {fixedIndexScore && indexScoreData && (
                  <div className="d-flex align-items-center">
                    <span
                      className={`${baseClass}-value font-size-lg font-weight-bold mr-2 ${getBackgroundColorClass(
                        parseInt(fixedIndexScore)
                      )}`}
                    >
                      {fixedIndexScore}
                    </span>
                    {!!indexScoreData.previousValue &&
                      indexScoreData.previousValue.value !== null && (
                        <DifferenceIndicator
                          className="ml-1 mr-3 cursor-pointer"
                          difference={
                            Number(fixedIndexScore) -
                            Number(indexScoreData.previousValue.value.toFixed())
                          }
                          performanceData={indexScoreData}
                          tooltipId={`difference-indicator-information-${title}`}
                          variableDateLevel={variable?.dateLevel ?? DateLevel.YEAR}
                        />
                      )}
                  </div>
                )}
              </div>
            )}
            {histogramDataAvailable && selectedBins.length > 0 ? (
              <div className="d-block mx-auto">
                <HistogramMemo
                  bins={selectedBins}
                  indexScore={indexScoreData?.value}
                  maintainAspectRatio={p.maintainAspectRatio}
                  parentWidth={width}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <DataUnavailable
                  className={`font-weight-bold mx-4 my-5 py-1 ${baseClass}-unavailable`}
                  hideTooltip
                  title={indexScoreChartCopy.unavailable.distributions}
                  titleClass="font-size-sm"
                />
              </div>
            )}
          </div>
        );
      }}
    </ReactResizeDetector>
  );
};
