import _ from 'lodash';
import {
  AnalyticsDomain,
  BenchmarkValue,
  FeatureSettingType,
  FullPerformanceDatum,
  PerformanceDatum,
  TrackDomainTypes,
  TrackDomainsFeatureSettings,
  TrackVariable,
  wrap,
} from 'core';
import { DataPointPosition } from 'client/shared/components/data-viz-d3/base/core';
import {
  COLORS_LIBERTY_HEX,
  COLORS_LIBERTY_L_HEX,
  indexScoreColors,
} from 'client/shared/core/colors';
import {
  AnalyticsBenchmarkValue,
  AnalyticsValueType,
  BenchmarkFilter,
} from 'client/shared/graphql-client/graphql-operations.g';
import { ClientUrlUtils } from 'client/shared/core/helpers';
import { CrmTrackedEvent } from 'client/shared/containers/crm-track-event';

export function sanitizePercentageValue(value: number): number {
  return Math.min(Math.max(0, value), 100);
}

export function formatIndicatorValue(
  value: number | null,
  type: AnalyticsValueType,
  decimalPoints: number = 2,
  previewMode: boolean = false,
  compactDisplay?: boolean
) {
  const locale = 'en-US';
  const compactFormatter = new Intl.NumberFormat(locale, {
    notation: 'compact',
    compactDisplay: 'short',
  });
  if (value === null && !previewMode) {
    return '';
  }

  return wrap(() => {
    const roundedValue =
      previewMode || value === null
        ? 'XX'
        : Number(value.toFixed(decimalPoints)).toLocaleString(locale);
    const compactValue =
      previewMode || value === null
        ? 'XX'
        : compactFormatter.format(Number(value?.toFixed(decimalPoints)));
    switch (type) {
      case AnalyticsValueType.PERCENT:
        return `${compactDisplay ? compactValue : roundedValue}%`;
      case AnalyticsValueType.CURRENCY:
        return `$${compactDisplay ? compactValue : roundedValue}`;
      case AnalyticsValueType.AMOUNT:
        return `${compactDisplay ? compactValue : roundedValue}`;
    }
  });
}

export const TrackLearnMoreValues: Record<
  AnalyticsDomain,
  { readonly learnMoreLink: string; readonly crmEvent: CrmTrackedEvent }
> = {
  SAFETY: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_SAFETY,
  },
  COMMUNITY_CHARACTERISTICS: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent:
      CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_CHARACTERISTICS,
  },
  COMMUNITY_DESIGN: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_DESIGN,
  },
  COMMUNITY_LIVABILITY: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent:
      CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_LIVABILITY,
  },
  EDUCATION_ARTS_CULTURE: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent:
      CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_EDUCATION_ARTS_CULTURE,
  },
  ECONOMY: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_ECONOMY,
  },
  NATURAL_ENVIRONMENT: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_NATURAL_ENVIRONMENT,
  },
  PARKS_AND_RECREATION: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent:
      CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_PARKS_AND_RECREATION,
  },
  HEALTH_AND_WELLNESS: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_HEALTH_AND_WELLNESS,
  },
  GOVERNANCE: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_GOVERNANCE,
  },
  INCLUSIVITY_AND_ENGAGEMENT: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent:
      CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_INCLUSIVITY_AND_ENGAGEMENT,
  },
  MOBILITY: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_MOBILITY,
  },
  UTILITIES: {
    learnMoreLink: ClientUrlUtils.admin.learnMore.path('track'),
    crmEvent: CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_UTILITIES,
  },
};

export const domainCardCopy = {
  indicatorsLabel: 'Community Statistics',
  residentSentimentLabel: 'Resident Sentiment',
  scoreLastUpdated: 'Score last updated',
  viewNcs: 'View the NCS',
  unavailable: 'Unavailable',
  unknown: 'Unknown',
  indexScoreTitle: 'Domain Index Score',
  indexDateUpdate: 'Index calculation is based on most recent indicator data',
  lastUpdated: 'Last updated',
  lastUpdatedOn: 'Last updated on',
};

export const getBackgroundColorClass = (value: number) => {
  if (_.inRange(value, 0, 21)) return indexScoreColors.valencia.text;
  else if (_.inRange(value, 21, 41)) return indexScoreColors.canaryD.text;
  else if (_.inRange(value, 41, 61)) return indexScoreColors.gray60.text;
  else if (_.inRange(value, 61, 81)) return indexScoreColors.aqua.text;
  else if (_.inRange(value, 81, 101)) return indexScoreColors.lime.text;
};

export const getBorderColorClass = (value: number | null) => {
  if (value === null) {
    return 'border-gray-30';
  }
  if (_.inRange(value, 0, 21)) return 'border-valencia';
  else if (_.inRange(value, 21, 41)) return 'border-canary-d';
  else if (_.inRange(value, 41, 61)) return 'border-gray-60';
  else if (_.inRange(value, 61, 81)) return 'border-aqua';
  else if (_.inRange(value, 81, 101)) return 'border-lime';
};

export const generateDoubleBarColumn = (
  label: string,
  performanceDatum: PerformanceDatum | null
) => {
  const previousValue =
    !!performanceDatum &&
    'previousValue' in performanceDatum &&
    performanceDatum.previousValue?.value.toFixed();
  return [
    {
      label,
      value: Number(performanceDatum?.value?.toFixed()) || 0,
      isNull: _.isNil(performanceDatum?.value),
      color: COLORS_LIBERTY_HEX,
      position: DataPointPosition.RIGHT,
    },
    {
      label,
      value: Number(previousValue) || 0,
      isNull: _.isNil(previousValue),
      color: COLORS_LIBERTY_L_HEX,
      position: DataPointPosition.LEFT,
    },
  ];
};

export function analyticsBenchmarkToClient(
  analyticsBenchmark: AnalyticsBenchmarkValue
): BenchmarkValue {
  return wrap(() => {
    switch (analyticsBenchmark) {
      case AnalyticsBenchmarkValue.HIGHER:
      case AnalyticsBenchmarkValue.MORE_FAVORABLE:
        return BenchmarkValue.HIGHER;
      case AnalyticsBenchmarkValue.LOWER:
      case AnalyticsBenchmarkValue.LESS_FAVORABLE:
        return BenchmarkValue.LOWER;
      case AnalyticsBenchmarkValue.SIMILAR:
        return BenchmarkValue.SIMILAR;
      case AnalyticsBenchmarkValue.MUCH_HIGHER:
        return BenchmarkValue.MUCH_HIGHER;
      case AnalyticsBenchmarkValue.MUCH_LOWER:
        return BenchmarkValue.MUCH_LOWER;
    }
  });
}

export const analyticsBenchmarkNumericValues: Record<
  AnalyticsBenchmarkValue,
  number
> = {
  [AnalyticsBenchmarkValue.MUCH_HIGHER]: 7,
  [AnalyticsBenchmarkValue.MORE_FAVORABLE]: 6,
  [AnalyticsBenchmarkValue.HIGHER]: 5,
  [AnalyticsBenchmarkValue.SIMILAR]: 4,
  [AnalyticsBenchmarkValue.LOWER]: 3,
  [AnalyticsBenchmarkValue.LESS_FAVORABLE]: 2,
  [AnalyticsBenchmarkValue.MUCH_LOWER]: 1,
};

export const getVariableByName = (
  variables: readonly TrackVariable[],
  variableName: string
) => variables?.find((variable) => variable.name === variableName);

export const NCS_LINK =
  'https://info.polco.us/request-information?hsCtaTracking=0f3ac975-c893-4bbf-a641-b2e28f3d9fc9%7C8c1e576b-4e92-42f0-9217-cd3d8504b60e';

export const getFilteredBenchmarkValue = (
  datum: Pick<FullPerformanceDatum, 'benchmarkValues'>,
  benchmarkFilter: BenchmarkFilter
) => {
  return datum.benchmarkValues.find((bv) => bv.filter === benchmarkFilter)?.value;
};

export const getFilteredBenchmarkFooter = (benchmarkFilter: BenchmarkFilter) => {
  switch (benchmarkFilter) {
    case BenchmarkFilter.DEFAULT:
      return 'Benchmarked against all U.S. communities for which these data are available';
    case BenchmarkFilter.STATE_AND_POP:
      return `Benchmarked against all U.S. communities of similar population size and in my state for which these data are available`;
    case BenchmarkFilter.POPULATION:
      return `Benchmarked against all U.S. communities of similar population size for which these data are available`;
    case BenchmarkFilter.STATE:
      return `Benchmarked against all communities in my state for which these data are available`;
  }
};

export function getLatest<T extends FullPerformanceDatum>(
  data: readonly T[]
): T | undefined {
  return _(data).maxBy((datum) => datum.recordedAt);
}
export type TrackFeatureSettings =
  | TrackDomainTypes
  | FeatureSettingType.TRACK_DASHBOARD;
export const TrackFeatureSettings: Record<TrackFeatureSettings, FeatureSettingType> =
  {
    ...TrackDomainsFeatureSettings,
    [FeatureSettingType.TRACK_DASHBOARD]: FeatureSettingType.TRACK_DASHBOARD,
  };

// Note: be careful with the order of the entries here, it defines the order in which domains
// will be displayed in /n/admin/track/domains
export const TRACK_DOMAINS_COPY: Record<
  TrackDomainTypes,
  { readonly title: string; readonly description: string }
> = {
  TRACK_COMMUNITY_DESIGN: {
    title: 'Community Design',
    description:
      'A well-designed and well-planned built environment (residential, commercial, and public areas) improves communities by encouraging smart land use and zoning, ensuring that affordable housing is accessible to all, and providing attractive communal spaces.',
  },
  TRACK_MOBILITY: {
    title: 'Mobility',
    description:
      'The ease with which residents can move about their communities, whether for commuting, leisure, or recreation, plays a major role in the quality of life for all who live, work, and play in the community.',
  },
  TRACK_UTILITIES: {
    title: 'Utilities',
    description:
      'Services such as water, gas, electricity, and internet play a vital role in ensuring the physical and economic health and well-being of the communities they serve.',
  },
  TRACK_ECONOMY: {
    title: 'Economy',
    description:
      'Businesses, business networks and associations, and local governments all work together to help foster sustainable growth, create jobs, and promote a thriving local economy.',
  },
  TRACK_INCLUSIVITY_AND_ENGAGEMENT: {
    title: 'Inclusivity and Engagement',
    description:
      'A community in which all members feel welcome and to which they feel that they belong provides a foundation for building trust and engagement to tackle local problems and improve quality of life for all.',
  },
  TRACK_EDUCATION_ARTS_AND_CULTURE: {
    title: 'Education, Arts and Culture',
    description:
      'Participation in the arts, educational opportunities, and cultural activities are linked to increased civic engagement, greater social capital, and enhanced community enjoyment.',
  },
  TRACK_HEALTH_AND_WELLNESS: {
    title: 'Health and Wellness',
    description:
      'Community amenities and offerings directly impact residents’ physical and emotional health and general well being overall.',
  },
  TRACK_NATURAL_ENVIRONMENT: {
    title: 'Natural Environment',
    description:
      'The natural environment and environmental health plays a vital role in the well-being of residents. Providing natural spaces and ensuring a healthy relationship between people and their environment has a direct and profound effect on quality of life.',
  },
  TRACK_PARKS_AND_RECREATION: {
    title: 'Parks and Recreation',
    description:
      'A strong, active system of parks and recreation creates desirable communities with opportunities to stay active and engaged.',
  },
  TRACK_SAFETY: {
    title: 'Safety',
    description:
      'Safety is the cornerstone of an attractive community. Ensuring all residents feel secure and protected from crime, fire, and natural hazards is essential to a high quality of life.',
  },
};
