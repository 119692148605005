import * as React from 'react';
import _ from 'lodash';
import {
  CtaLinkType,
  FipsArea,
  getDatumCurrentPubForYear,
  TrackDatum,
  TrackVariable,
  VisualizationType,
} from 'core';
import {
  DashboardBenchmarkSurveys,
  domainLabels,
} from 'client/shared/core/track/labels';
import { TabBar, TabBarItem } from 'client/shared/components/base/tab-bar';
import { PrimaryIndicator } from 'client/shared/components/primary-indicator';
import { DismissableContainer } from 'client/shared/components/dismissable-container';
import {
  ActionLink,
  Btn,
  ButtonTypes,
  Dropdown,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { CTA_COPIES, NCS_WARNING_COPIES, SURVEYS } from './copy';
import './styles.scss';
import { NCSFactorsModal } from 'client/shared/components/ncs-factors-modal';
import {
  CTAPolcoSiteContent,
  BlockType,
} from 'client/shared/components/cta-polco-site-content';
import {
  AnalyticsDomain,
  BenchmarkFilter,
} from 'client/shared/graphql-client/graphql-operations.g';
import {
  getFilteredBenchmarkFooter,
  NCS_LINK,
} from 'client/shared/core/performance-data';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { useGenerateImage } from 'client/shared/hooks';
import { DomainCardOptionsMenu } from 'client/shared/components/domain-community-statistics-card';
import { EmbedLogoFooter } from '../embed-logo-footer';
import { EmbedDataPointContext } from '../visualization-picker';
import { DownloadImageButton } from '../download-image';

const baseClass = 'pn-resident-sentiment';

interface Props {
  readonly benchmarkFilter: BenchmarkFilter;
  readonly className?: string;
  readonly domain: AnalyticsDomain;
  readonly hideTabBar?: boolean;
  readonly hideSaveAsImage?: boolean;
  readonly sentimentValues: readonly TrackVariable[];
  readonly currentFips: FipsArea;
  readonly disableSaveVisualization?: boolean;
  readonly saveVisualization?: (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => Promise<void>;
  readonly showFooter?: boolean;
  readonly embedContext?: EmbedDataPointContext;
  readonly canNotManageDataPoints?: boolean;
  readonly showOnlyTopSentimentValues?: boolean;
}

const NCS_BANNER_DISMISSED_KEY = 'DISMISSED_NCS_SECTION:domain-dashboard';

export const ResidentSentiment: React.FC<Props> = (p) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<TabBarItem | null>(null);
  const [selectedYear, setSelectedYear] = React.useState<number>(0);
  const [dropdownYears, setDropdownYears] = React.useState<number[]>([]);
  const [groupedVariables, setGroupedVariables] = React.useState<
    Record<string, never> | Record<string, TrackVariable[]>
  >({});
  const [tabs, setTabs] = React.useState<TabBarItem[]>([]);

  const ref = React.useRef<HTMLDivElement>(null);
  const generateImage = useGenerateImage(
    ref,
    `${domainLabels[p.domain]} Resident Sentiment`
  );
  const doGenerateImage = React.useCallback(async () => {
    await generateImage();
  }, [generateImage]);
  const embeddableContentEnabled =
    useFlagEnabled(KnownFlag.EMBEDDABLE_TRACK_DATA) && !p.canNotManageDataPoints;
  const [showOnlyTopSentimentValues, setShowOnlyTopSentimentValues] = React.useState(
    p.showOnlyTopSentimentValues !== undefined ? p.showOnlyTopSentimentValues : true
  );
  const [selectedSentimentValueToPrint, setSelectedSentimentValueToPrint] =
    React.useState<string | null>(null);

  const surveyVariableFiltersByDomain =
    residentSentimentVariablesFiltersByDomain[p.domain];
  React.useEffect(() => {
    const sources = Object.keys(surveyVariableFiltersByDomain);

    const calculatedTabs = sources.map((source: string) => ({
      key: source,
      label: SURVEYS[source].title || source,
    }));

    const groupedValues = sources.reduce(
      (
        prev: Record<string, never> | Record<string, TrackVariable[]>,
        source: string
      ) => {
        // For grouping purposes (grouping by "source" (survey) and year)
        // we use the filter only variable names and not "top" interest variables
        const filterByNames = ({ name }: TrackVariable) =>
          surveyVariableFiltersByDomain[source](name, false);

        const filteredSentimentValues = _.sortBy(
          p.sentimentValues.filter(filterByNames),
          ({ name }) => name
        );

        prev[source] = filteredSentimentValues;

        return prev;
      },
      {}
    );

    // If there is only one source with values and hideTabBar is true, it's the case of a saved data visualization
    // So we have to make sure to selected the right tab containing the saved variable
    const tabToSelect =
      p.sentimentValues.length === 1
        ? calculatedTabs.find((t) => t.key === p.sentimentValues[0].source)
        : undefined;
    setGroupedVariables(groupedValues);
    setTabs(calculatedTabs);
    setSelectedTab(tabToSelect || calculatedTabs[0]);
  }, [p.domain, p.sentimentValues, p.hideTabBar, surveyVariableFiltersByDomain]);

  React.useEffect(() => {
    if (!selectedTab) {
      return;
    }

    const calculatedYears = _.uniq(
      groupedVariables[selectedTab.key].flatMap((sv) =>
        sv.areasData.flatMap((ad) =>
          ad.performanceData.map((pd) => pd.recordedAt.getUTCFullYear())
        )
      )
    )
      .sort()
      .reverse();

    setDropdownYears(calculatedYears);
    setSelectedYear(calculatedYears.length ? Math.max(...calculatedYears) : 0);
  }, [groupedVariables, selectedTab]);

  React.useEffect(() => {
    if (selectedSentimentValueToPrint) {
      doGenerateImage()
        .then(() => {
          setSelectedSentimentValueToPrint(null);
        })
        .catch(console.error);
    }
  }, [doGenerateImage, selectedSentimentValueToPrint]);

  if (!tabs.length || !selectedTab) {
    return null;
  }

  // creates the variables related to the selected tab
  const copies = SURVEYS[selectedTab.key] || {};
  const tabSentimentValues = groupedVariables[selectedTab.key] || [];
  const filterToApply = surveyVariableFiltersByDomain[selectedTab.key]
    ? ({ name }: TrackVariable) =>
        surveyVariableFiltersByDomain[selectedTab.key](
          name,
          showOnlyTopSentimentValues
        )
    : () => false;
  const tabSentimentValuesToShow = tabSentimentValues.filter(filterToApply);
  const showNcsBenchmark = groupedVariables[DashboardBenchmarkSurveys.NCS]
    ? groupedVariables[DashboardBenchmarkSurveys.NCS].every(
        (variable) => !variable.areasData.length
      )
    : true;

  const ctaProps = {
    link: NCS_LINK,
    text: CTA_COPIES.viewTheNCS(copies.shortTitle),
    type: CtaLinkType.EXTERNAL,
  };

  const renderPrimaryIndicators = (
    sentimentValues: TrackVariable[],
    currentFips: FipsArea,
    getVariableOptions?: (
      sentimentValue: TrackDatum,
      variable: TrackVariable
    ) => void
  ) => (
    <div
      className={`${baseClass}-indicator-container d-flex flex-column`}
      style={{ gap: 16 }}
    >
      {sentimentValues.map((sentimentValue, index) => {
        const yearDatum = getDatumCurrentPubForYear(
          currentFips,
          selectedYear,
          sentimentValue
        );
        return (
          <PrimaryIndicator
            benchmarkFilter={p.benchmarkFilter}
            className={
              selectedSentimentValueToPrint !== null &&
              selectedSentimentValueToPrint !== sentimentValue.name
                ? 'd-none'
                : ''
            }
            indicatorData={yearDatum}
            indicatorOptionsContent={
              getVariableOptions &&
              yearDatum &&
              getVariableOptions(yearDatum, sentimentValue)
            }
            key={index}
            showFooter={p.showFooter}
            variable={sentimentValue}
          />
        );
      })}
    </div>
  );

  const footer = getFilteredBenchmarkFooter(p.benchmarkFilter);

  const getSentimentValueIndicatorOptions = (
    sentimentValue: TrackDatum,
    variable: TrackVariable
  ): React.ReactNode => {
    return embeddableContentEnabled &&
      p.saveVisualization &&
      tabSentimentValues[0] &&
      !!p.currentFips.id ? (
      <DomainCardOptionsMenu
        benchmarkFilter={p.benchmarkFilter}
        canNotManageDataPoints={p.canNotManageDataPoints}
        className={`${baseClass}-resident-sentiment-menu-options-wrappper mr-1`}
        currentFips={p.currentFips.id}
        disableSaveVisualization={p.disableSaveVisualization}
        domain={p.domain}
        embeddableTrackEnabled={embeddableContentEnabled}
        events={{
          generateImage: async () => {
            setSelectedSentimentValueToPrint(variable.name);
          },
          saveVisualization: p.saveVisualization,
        }}
        groupFips={[p.currentFips.id]}
        imageDownloadEnabled
        recentValue={sentimentValue}
        variable={variable}
        visualizationType={VisualizationType.PROGRESS}
      />
    ) : (
      !p.embedContext && !p.hideSaveAsImage && (
        <DownloadImageButton
          buttonClassName={`save-image-track-${p.domain}-resident-sentiment-${selectedTab}`}
          customDownloadImageAction={async () =>
            setSelectedSentimentValueToPrint(variable.name)
          }
          imageRef={ref}
          name={`${domainLabels[p.domain]} Resident Sentiment`}
        />
      )
    );
  };

  return (
    <div
      className={`${baseClass} p-3 ${p.className ?? ''} ${
        p.embedContext === EmbedDataPointContext.VISUALIZATION ? 'border-0' : ''
      }`}
      ref={ref}
    >
      {!p.hideTabBar && (
        <TabBar
          className={`${selectedSentimentValueToPrint ? 'd-none' : ''}`}
          selected={selectedTab}
          tabSelected={(tab) => {
            setSelectedTab(tab);
            setShowOnlyTopSentimentValues(true);
          }}
          tabs={tabs}
        />
      )}
      <div
        className={`${baseClass}-header d-flex flex-wrap justify-content-between align-items-center pt-2`}
      >
        <h1 className={`${baseClass}-title font-size-lg font-weight-bold mb-0`}>
          {copies.title}
        </h1>
        <div className="d-flex align-items-center">
          <Dropdown
            className="mr-1"
            disabled={dropdownYears.length <= 1}
            keySelect={(year) => `${year}`}
            labelSelect={(year) => `${year}`}
            onChange={(year) => {
              setSelectedYear(year);
              if (!p.hideTabBar) setShowOnlyTopSentimentValues(true);
            }}
            options={dropdownYears}
            prompt="Year"
            value={selectedYear}
          />
        </div>
      </div>
      {showNcsBenchmark ? (
        <>
          <DismissableContainer
            dismissedKey={`${NCS_BANNER_DISMISSED_KEY}-${p.domain}-${selectedTab.key}`}
            key={selectedTab.key}
          >
            {({ dismiss }) => {
              return (
                <CTAPolcoSiteContent
                  blockType={BlockType.NCS_BENCHMARK}
                  className={`${baseClass}-ncs-section bg-canary-xl mt-4 mb-3`}
                  ctaProps={ctaProps}
                  dismiss={dismiss}
                  key={selectedTab.key}
                  textContent={CTA_COPIES.description(copies.title)}
                  title={CTA_COPIES.title}
                />
              );
            }}
          </DismissableContainer>
        </>
      ) : (
        <div className="d-flex flex-row">
          <p className={`${baseClass}-description flex-grow-1 mb-3`}>
            {copies.description}
          </p>
        </div>
      )}
      {renderPrimaryIndicators(
        tabSentimentValuesToShow,
        p.currentFips,
        getSentimentValueIndicatorOptions
      )}
      {tabSentimentValues.length > 1 && (
        <Btn
          action={() => setShowOnlyTopSentimentValues((prev) => !prev)}
          className={`${selectedSentimentValueToPrint ? 'd-none' : 'mt-3'}`}
          type={ButtonTypes.SEAMLESS}
        >
          {showOnlyTopSentimentValues
            ? `Show all ${copies.title} data`
            : `Hide ${copies.title} data`}
        </Btn>
      )}
      {selectedTab.key === DashboardBenchmarkSurveys.NCS && !showNcsBenchmark && (
        <div
          className={`${baseClass}-ncs-modal-warning-data pt-3 px-2 font-size-sm d-flex align-items-center`}
        >
          <MaterialIcon
            className="font-size-sm mr-2 align-self-center"
            icon={MaterialIconName.INFO_OUTLINE}
          />
          <div>
            {NCS_WARNING_COPIES.description}
            <ActionLink
              action={() => setModalOpen(true)}
              className={`${baseClass}-modal-button ml-2 no-show-in-image`}
            >
              {NCS_WARNING_COPIES.linkText}
            </ActionLink>
            <NCSFactorsModal
              domain={domainLabels[p.domain]}
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
            />
          </div>
        </div>
      )}
      <DownloadImageFooter footer={footer} />
      {p.embedContext === EmbedDataPointContext.VISUALIZATION && <EmbedLogoFooter />}
    </div>
  );
};

const residentSentimentVariablesFiltersByDomain: Record<
  AnalyticsDomain,
  Record<string, (variableName: string, filterTop: boolean) => boolean>
> = {
  SAFETY: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_320'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
    [DashboardBenchmarkSurveys.NLES]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['nles_0'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('nles'),
  },
  INCLUSIVITY_AND_ENGAGEMENT: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_900'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  HEALTH_AND_WELLNESS: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_393'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  EDUCATION_ARTS_CULTURE: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_399'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  ECONOMY: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_169'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
    [DashboardBenchmarkSurveys.NBS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['nbs_169'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('nbs'),
  },
  COMMUNITY_DESIGN: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_392'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  MOBILITY: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_156'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  UTILITIES: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_108'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  PARKS_AND_RECREATION: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop
        ? ['ncs_905'].includes(vn)
        : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  NATURAL_ENVIRONMENT: {
    [DashboardBenchmarkSurveys.NCS]: (vn: string, filterTop: boolean) =>
      filterTop ? ['ncs_96'].includes(vn) : vn.toLocaleLowerCase().startsWith('ncs'),
  },
  COMMUNITY_CHARACTERISTICS: {},
  GOVERNANCE: {},
  COMMUNITY_LIVABILITY: {},
};
